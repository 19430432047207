import React, {useState, useEffect} from "react";
import Header from "../../components/layout/header/header";
import NavigationBar from "../../components/layout/Navigationbar/NavigationBar";
import CurrentEnergyCard from "v2/components/specific/ChargerWidgets/chargerCurrentEnergyCard";
import TotalChargersCard from "v2/components/specific/ChargerWidgets/TotalChargersCard";
import ConnectorTypesCard from "v2/components/specific/ChargerWidgets/chargerTypeCard";
import ChargersBookings from "v2/components/specific/ChargerWidgets/chargerBookings";
import AvailableChargers from "v2/components/specific/ChargerWidgets/chargerAvailability";
import ButtonsBar from "v2/components/specific/ChargerWidgets/addChargerButtons"
import Charger1 from "v2/assets/images/Charger1.png"
import Charger2 from "v2/assets/images/Charger2.png"
import Charger3 from "v2/assets/images/Charger3.png"
import Charger4 from "v2/assets/images/Charger4.png"
import Charger5 from "v2/assets/images/Charger5.png"
import Charger6 from "v2/assets/images/Charger6.png"
import axios from 'axios';

// Static data for charger types and availability

const chargerData = [20, 15, 30, 25, 10];
    // Array of objects representing different chargers with their availability and type
const stationChargers = [
    { id: 1, name: 'Vertecharge', location: 'Rhodium Station', types: [{ name: "Type 1", available: true },{ name: "Type 2", available: false }],  image: Charger1 },
    { id: 2, name: 'Vertecharge', location: 'Prestige Station', types: [{ name: "CHAdeMO", available: false },{ name: "Type 2", available: true  }], image: Charger2 },
    { id: 3, name: 'Vertecharge', location: 'Gulberg Station', types: [{ name: "GB/T", available: true },{ name: "Type 2", available: true  } ], image: Charger3 },
    { id: 4, name: 'Vertecharge', location: 'Lawrence Station', types: [{ name: "CCS", available: true },{ name: "CHAdeMO", available: false }], image: Charger4 },
    { id: 5, name: 'Vertecharge', location: 'Mall Road Station', types: [{ name: "Type 1", available: false }], image:Charger5 },
    { id: 6, name: 'Vertecharge', location: 'Vertecharge Station',types: [{ name: "CHAdeMO", available: true }, { name: "Type 2", available: false }], image: Charger6 },
  ];
  const AllChargers = () => {
  const [stationInfo, setStationInfo] = useState({ total_stations: 1, active_stations: 0, inactive_stations: 1 });
  
    // Effect hook for fetching station status from the API on component mount
  // Fetching data from API
  useEffect(() => {
    async function fetchStationStatus() {
      try {
        const response = await axios.get(
          "https://verte-charge-platforms-backend-test.vertecharge.com/station/status-summary?platform=web",
          {
            //api for giving stations status summary showing total,active and inactive stations
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
            timeout: 5000,
          }
        );

        // Assuming the API returns data directly in the required format
        if (response.data && response.data.total_stations !== undefined) {
          setStationInfo({
            total_stations: response.data.total_stations,
            active_stations: response.data.active_stations,
            inactive_stations: response.data.inactive_stations,
          });
        } else {
          console.error("Unexpected data structure:", response.data);
        }

        console.log("Station Status:", response.data);
      } catch (error) {
        console.error("Error fetching station data:", error);
      }
    }
    fetchStationStatus();
  }, []);

  const chargersBookings = [
    { id: 1, type: "Type 2", time: "20 Dec at 3:30 pm", status: "Ongoing" },
    { id: 2, type: "ChAdeMO", time: "12 Nov at 1:00 pm", status: "Cancelled" },
    { id: 3, type: "GBT", time: "08 Oct at 12:00 am", status: "Pending" },
    { id: 4, type: "Type 1", time: "12 Oct at 4:00 pm", status: "Successful" },
    { id: 5, type: "CCS", time: "03 Jun at 11:00 am", status: "Pending" },
    { id: 6, type: "CCS2", time: "03 Mar at 9:00 pm", status: "Successful" },
    { id: 7, type: "ChAdeMO", time: "12 Nov at 1:00 pm", status: "Cancelled" },
  ];

  return (
    <div className="overflow-y-auto p-[32px]">
      <ButtonsBar />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[24px] ">
        {/*chart showing operational and non operational chargers */}
        <TotalChargersCard stationData={stationInfo} />
        {/* chart showing connector types */}
        <ConnectorTypesCard ChargerTypeData={chargerData} />
        {/* chart showing total power consumption */}
        <CurrentEnergyCard />
      </div>
      <div className="grid grid-cols-1 mt-[24px] md:grid-cols-1 lg:grid-cols-2 gap-[24px] ">
        {/* charger container */}
        <AvailableChargers chargers={stationChargers} />
    {/* booking container  */}
        <ChargersBookings bookings={chargersBookings} />
      </div>
    </div>
  );
};

export default AllChargers;

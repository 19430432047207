import React, { useEffect, useRef } from "react";
import "chart.js/auto";
import ApexCharts from "apexcharts";

const TotalChargersCard = ({ stationData }) => {
  const getChartOptions = () => {
    return {
      series: [stationData.active_stations, stationData.inactive_stations],
      colors: ["#333333", "#A6DAB2"],
      chart: {
        height: "245px",
        width: "100%",
        type: "donut",
      },
      labels: ["Operational Chargers", "Non-Operational Chargers"],
      legend: {
        position: "bottom",
        fontFamily: "Inter, sans-serif",
        itemMargin: {
          vertical: 5,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
            labels: {
              name: {
                offsetY: -10,
              },
              show: true,
              value: {
                fontSize: "26px",
                offsetY: 0,
                align: "center",
                color: "#333",
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                label: "Total Chargers",
                align: "center",
                fontSize: "10px",
                offsetY: 0,
                fontFamily: "Inter, sans-serif",
                formatter: function (w) {
                  // Total count from data
                  return stationData.total_stations.toString();
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ["transparent"],
      },
    };
  };

  const chartRef = useRef(null);

  useEffect(() => {
    const chart = new ApexCharts(chartRef.current, getChartOptions());
    chart.render();

    return () => {
      if (chart && typeof chart.destroy === "function") {
        chart.destroy();
      }
    };
  }, [stationData]); // Depend on stationData object

  return (
    <div className="max-h-[278px] w-full bg-white rounded-lg shadow-md">
      <div className="max-h-[170px]">
        <div ref={chartRef}></div>
      </div>
    </div>
  );
};

export default TotalChargersCard;

import React, { useEffect, useRef } from 'react';
import 'chart.js/auto';
import ApexCharts from 'apexcharts';

import { GreenArrowSvg } from 'v2/assets/svgIcons/GreenArrowSvg';

const ChargerCard = () => {
	const getChartOptions = () => {
		return {
			series: [40, 60],
			colors: ['#333333', '#A6DAB2'],
			chart: {
				height: '500px', // Increase height
				width: '100%', // Use 100% to make the chart responsive or set a specific width like 350px
				type: 'donut',
			},
			labels: ['Operational Chargers', 'Non-Operational Chargers'],
			legend: {
				position: 'bottom',
				fontFamily: 'Inter, sans-serif',
				itemMargin: {
					vertical: 5, // Adjust spacing between legend items
				},
			},
			plotOptions: {
				pie: {
					donut: {
						size: '50%',
						labels: {
							name: {
								offsetY: -10, // Adjust according to your needs for the label
							},
							show: true,
							value: {
								fontSize: '26px',
								offsetY: 0, // Adjust this as necessary to vertically center
								align: 'center',
								color: '#333', // Use undefined to use the default color
								formatter: function (val) {
									return val;
								},
							},
							total: {
								show: true,
								label: 'Chargers',
								align: 'center',
								fontSize: '10px',
								offsetY: 0, // Adjust this as necessary to vertically center
								fontFamily: 'Inter, sans-serif',
								formatter: function (w) {
									return '132';
								},
							},
						},
					},
				},
			},
			dataLabels: {
				enabled: false, // This disables the data labels (percentages)
			},
			stroke: {
				colors: ['transparent'],
			},
		};
	};
	const chartRef = useRef(null);

	useEffect(() => {
		const chart = new ApexCharts(chartRef.current, getChartOptions());
		chart.render().then(() => {
			// Target the rendered chart and find the specific text element
			const totalLabel = Array.from(
				chartRef.current.querySelectorAll('.apexcharts-text')
			).find((el) => el.textContent === '132');
			// Apply bold font weight and font size to the '132' text element
			if (totalLabel) {
				totalLabel.setAttribute('font-weight', 'bold');
				totalLabel.setAttribute('font-size', '26px'); // Set font size to 26px
			}
		});

		// Cleanup function to destroy the chart when the component unmounts
		return () => {
			if (chart && typeof chart.destroy === 'function') {
				chart.destroy();
			}
		};
	}, []); // Empty dependency array ensures effect is only run once

	return (
		<div className="w-[214px] mr-[24px] h-[325px] p-6 bg-white rounded-lg shadow-md max-w-sm">
			<div ref={chartRef}></div>
			<div className="flex justify-center items-center">
				<button className="flex flex-row mt-[30px]">
					<div className="text-green-500">View All Chargers</div>
					<div className="p-[7px]">
						<GreenArrowSvg />
					</div>
				</button>
			</div>
		</div>
	);
};

export default ChargerCard;

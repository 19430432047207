import React from "react";
import Dashboard from "v2/pages/Dashboard/dashboard";
import AllStations from "v2/pages/AllStations/allStations";
import AllChargers from "v2/pages/AllChargers/allChargers";
import EmailOTP from "v1/pages/EmailOTP";
import EmptyState from "v2/pages/AddStations/EmptyState";
import { Routes, Route } from "react-router-dom";
import SpecificCharger from "v2/pages/AllChargers/specificCharger";
import EmptyChargersScreen from "v2/pages/AllChargers/emptyCharger";
import AddChargerPopup from "v2/pages/AllChargers/addChargerPopup";
import NewNavigationBar from "v2/components/layout/Navigationbar/NavigationBar.jsx";
import Header from "v2/components/layout/header/header";
import StationDisplay from "v2/pages/StationDisplay/stationDisplay";
import Login from "v2/pages/Login/login";
import Congratulations from "v2/pages/AddStations/CongratulationsScreen";
import Signup from "v2/pages/SignUp/signup";
import { AuthProvider } from "v1/features/ui/AuthContext";
import { BrowserRouter, Outlet, Navigate } from "react-router-dom";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* Routes without NavigationSidebar and Header */}
          <Route path="/" element={<Navigate replace to="/signup" />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/emailOTP" element={<EmailOTP />} />
          <Route path="/login" element={<Login />} />
          {/* Wrapper Route for pages with NavigationSidebar and Header */}
          <Route path="/dashboard" element={<LayoutWithSidebar />}>
            <Route index element={<Dashboard />} />
            {/* <Route path="/congratulations" component={Congratulations} /> */}
            <Route path="/dashboard/stations" element={<AllStations />} />
            <Route path="/dashboard/addStation" element={<EmptyState />} />
            <Route path="/dashboard/chargers" element={<AllChargers />} />
            <Route path="/dashboard/chargers/a" element={<SpecificCharger />} />
            <Route
              path="/dashboard/chargers/emptyCharger"
              element={<EmptyChargersScreen />}
            />
            <Route
              path="/dashboard/chargers/addCharger"
              element={<AddChargerPopup />}
            />
            <Route
              path="/dashboard/stations/rhodium"
              element={<StationDisplay />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

function LayoutWithSidebar() {
  return (
    // Using flex to layout sidebar and content side by side
    <div className="flex z-10 min-h-screen max-h-screen overflow-hidden">
      <NewNavigationBar />
      {/* Main content area */}
      <div className="flex-1 flex flex-col">
        {/* Fixed header at the top of the content area */}
        <div className="w-full h-auto top-0 right-0 bg-gray-100 border-b">
          <Header
            mainText="Welcome back, Naveed"
            subText="Track, Manage and Forecast your stations and bookings."
          />
        </div>
        {/* Scrollable content area */}
        <div className="flex-1 overflow-y-auto">
          <Outlet /> {/* Nested routes will render here */}
        </div>
      </div>
    </div>
  );
}

export default App;

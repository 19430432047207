import React from 'react';

const bookings = [
  { id: 1, connector: "Type 2", date: "20 Dec", startTime: "1:00 pm", endTime: "2:00 pm", status: "Ongoing", pricingModel: "Fixed Price Model" },
  { id: 2, connector: "ChAdeMO", date: "20 Dec", startTime: "1:00 pm", endTime: "2:00 pm", status: "Cancelled", pricingModel: "Energy Based Model" },
  { id: 3, connector: "GBT", date: "20 Dec", startTime: "1:00 pm", endTime: "2:00 pm", status: "Pending", pricingModel: "Fixed Price Model" },
  { id: 4, connector: "Type 1", date: "20 Dec", startTime: "1:00 pm", endTime: "2:00 pm", status: "Successful", pricingModel: "Fixed Price Model" },
  { id: 5, connector: "CCS", date: "20 Dec", startTime: "1:00 pm", endTime: "2:00 pm", status: "Pending", pricingModel: "Energy Based Model" },
  { id: 6, connector: "CCS2", date: "20 Dec", startTime: "1:00 pm", endTime: "2:00 pm", status: "Successful", pricingModel: "Fixed Price Model" },
];

const BookingsTable = () => {
  return (
    <div className="bg-white p-6 shadow rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Bookings</h2>
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase">
          <tr>
            <th scope="col" className="px-6 py-3">ID</th>
            <th scope="col" className="px-6 py-3">Connector</th>
            <th scope="col" className="px-6 py-3">Date</th>
            <th scope="col" className="px-6 py-3">Start Time</th>
            <th scope="col" className="px-6 py-3">End Time</th>
            <th scope="col" className="px-6 py-3">Booking Status</th>
            <th scope="col" className="px-6 py-3">Pricing Model</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking) => (
            <tr key={booking.id} className="bg-white border-b">
              <td className="px-6 py-6">{booking.id}</td>
              <td className="px-6 py-6">{booking.connector}</td>
              <td className="px-6 py-6">{booking.date}</td>
              <td className="px-6 py-6">{booking.startTime}</td>
              <td className="px-6 py-6">{booking.endTime}</td>
              <td className="px-6 py-6">
                <span className={`px-3 py-1 rounded-full text-xs font-medium shadow-sm ${
                  booking.status === "Ongoing" ? "bg-[#ECFDF3] text-[#027A48]" :
                  booking.status === "Cancelled" ? "bg-[#FFF1F3] text-[#AE0000]" :
                  booking.status === "Pending" ? "bg-[#FFF5F1] text-[#FF9922]" :
                  "bg-[#EFF8FF] text-[#1448A1]"
                }`}>
                  {booking.status}
                </span>
              </td>
              <td className="px-6 py-4 text-black">{booking.pricingModel}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BookingsTable;

import React, { useState } from "react";

const AvailableChargers = ({ chargers }) => {
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(chargers.length / itemsPerPage);
  const currentChargers = chargers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / 2) * 2;
    return new Array(2).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h2 className="text-lg font-semibold">Chargers</h2>
      <div className="overflow-x-auto mt-3">
        <table className="w-full text-sm text-left text-black-100">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="py-3 px-8 text-center">Icon</th>
              <th scope="col" className="py-6 text-center">Charger Name</th>
              <th scope="col" className="py-3 text-center">Station</th>
              <th scope="col" className="py-3 text-center">Availability</th>
            </tr>
          </thead>
          <tbody>
            {currentChargers.map((charger, index) => (
              <tr key={index} className="bg-white border-b">
                <td className="py-4 px-6 text-center"><img src={charger.image} alt={charger.name} className="w-10 h-10" /></td>
                <td className="py-4 px-6 text-center">{charger.name}</td>
                <td className="py-4 px-6 text-center">{charger.location}</td>
                <td className="py-4 px-6 text-center">
                  {charger.types.map((type, idx) => (
                    <span key={idx} className={`block iline-block py-1 my-1 leading-wide whitespace-nowrap rounded-full shadow-sm ${type.available ? "bg-green-100 text-[#027A48]" : "bg-gray-100 text-[#999999]"}`}>
                      {type.name}
                    </span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          <ul className="flex list-none">
            {currentPage > 1 && (
              <li className="mx-1">
                <button onClick={() => paginate(currentPage - 1)} className="text-gray-500 hover:text-green-600">
                  &lt;
                </button>
              </li>
            )}
            {getPaginationGroup().map((item, index) => (
              <li key={index} className="mx-1">
                <button onClick={() => paginate(item)} className={`px-4 py-1 rounded-full ${item === currentPage ? "bg-green-500 text-white" : "bg-white border border-gray-300 text-gray-500 hover:bg-gray-200"}`}>
                  {item}
                </button>
              </li>
            ))}
            <li className="mx-1">...</li>
            
            {currentPage < pageCount && (
              <li className="mx-1">
                <button onClick={() => paginate(currentPage + 1)} className="text-gray-500 hover:text-green-600">
                  &gt;
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AvailableChargers;

import React from 'react';
import { BellIconSvg } from '../../../assets/svgIcons/BellIcon';
import ProfilePicture from '../../../assets/images/ProfilePicture.png';

const Header = ({ mainText, subText }) => {
	return (
    <div className="flex items-center justify-between p-4 bg-white text-white">
      <div>
        <h1 className="text-xl text-black font-bold">{mainText}</h1>
        <p className="text-sm text-gray-500">{subText}</p>
      </div>
      <div className="flex items-center">
        <div className="mr-4">
          <BellIconSvg className="w-6 h-6" />
        </div>
        <img src={ProfilePicture} className="w-[40px] h-[40px]" alt="" />
      </div>
    </div>
  );
};

export default Header;

import React, { useState, useEffect } from "react";

const bookingsData = [
  {
    id: 1,
    charger: "Type 2",
    date: "20 Dec",
    time: "3:30 pm",
    status: "Ongoing",
    location: "Rhodium Station",
  },
  {
    id: 2,
    charger: "ChAdeMO",
    date: "12 Nov",
    time: "1:00 pm",
    status: "Cancelled",
    location: "Vertecharge Station",
  },
  {
    id: 3,
    charger: "GBT",
    date: "08 Oct",
    time: "12:00 am",
    status: "Pending",
    location: "Prestige Station",
  },
  {
    id: 4,
    charger: "Type 1",
    date: "12 Oct",
    time: "4:00 pm",
    status: "Successful",
    location: "Lawrence Station",
  },
  {
    id: 5,
    charger: "CCS",
    date: "03 Jun",
    time: "11:00 am",
    status: "Pending",
    location: "Mall Road Station",
  },
  {
    id: 6,
    charger: "CCS2",
    date: "03 Mar",
    time: "9:00 pm",
    status: "Successful",
    location: "Gulberg Station",
  },
];

const statusStyles = {
  Ongoing: "text-green-600 bg-green-100",
  Cancelled: "text-red-600 bg-red-100",
  Pending: "text-yellow-600 bg-yellow-100",
  Successful: "text-blue-600 bg-blue-100",
};

const BookingWidget = () => {
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    setBookings(bookingsData);
    console.log(bookingsData); // Log the data to verify it
  }, []);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-lg font-semibold font-inter mb-4">
        Today's Bookings
      </h2>
      <div className="grid grid-cols-12 mb-2 ml-[24px] font-semibold">
        <div className="col-span-1 flex-1 font-inter text-[12px] text-left">
          No.
        </div>
        <div className="col-span-3 flex-1 font-inter text-[12px] text-left">
          Charger & Time
        </div>
        <div className="col-span-4 flex-1 font-inter text-[12px] text-left">
          Station
        </div>
        <div className="col-span-4 flex-1 font-inter text-[12px] text-left">
          Booking Status
        </div>
      </div>
      <ul>
        {bookings.length > 0 ? (
          bookings.map((booking) => (
            <li
              key={booking.id}
              className="grid grid-cols-12 items-center justify-between mb-4 p-2 border-b border-gray-200"
            >
              <div className="col-span-1 h-8 flex items-center justify-center text-lg font-semibold">
                {booking.id}
              </div>
              <div className="col-span-3 ml-4">
                <div className="text-sm font-medium">{booking.charger}</div>
                <div className="text-xs text-gray-500">
                  {booking.date} at {booking.time}
                </div>
              </div>
              <div className="col-span-4 text-sm text-gray-700">
                <div>{booking.location}</div>
                <div className="text-xs text-gray-500">
                  Booked for 30 minutes
                </div>
              </div>
              <div
                className={`col-span-2 py-1 rounded-lg text-sm text-center ${
                  statusStyles[booking.status]
                }`}
              >
                {booking.status}
              </div>
            </li>
          ))
        ) : (
          <li className="text-center text-gray-500">No bookings available</li>
        )}
      </ul>
      <div className="text-center mt-4">
        <button className="text-[14px] font-inter text-green-600">
          View All Bookings &gt;
        </button>
      </div>
    </div>
  );
};

export default BookingWidget;

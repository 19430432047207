import React, { useState, useEffect } from "react";
import axios from "axios"; // Ensure axios is installed or use fetch API instead
// import Header from "v2/components/layout/header/header.js";
import StationCard from "v2/components/Cards/StationCard.js";
import StationGraph from "v2/components/specific/StationWidgets/StationGraph.js";
import StationMap from "v2/components/specific/Map/stationMap.jsx";
// import NavigationBar from "v2/components/layout/Navigationbar/NavigationBar.jsx";
import defaultStation from "v2/assets/images/defaultStation.png";

export default function AllStations() {
  const [stations, setStations] = useState([]);

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const response = await fetch(
          "https://verte-charge-platforms-backend-test.vertecharge.com/station", //api to get all the stations of the user logged in
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched data:", data);

        // Validate that data is an array
        if (Array.isArray(data)) {
          setStations(data);
        } else {
          console.error("Expected data to be an array but received:", data);
          setStations([]); // Set stations to an empty array if the data is not as expected
        }
      } catch (error) {
        console.error("Failed to fetch stations", error);
        setStations([]); // Set stations to an empty array in case of an error
      }
    };

    fetchStations();
  }, []);

  return (
    <>
      <div>
        <div className="w-full bg-gray-50 relative overflow-x-hidden overflow-y-auto">
          <div className=" ml-4 mt-2 md:ml-[32px] md:mt-[10px] mr-4 p-4 lg:p-30">
            <div className="flex flex-row items-center justify-between p-2">
              <span className="text-black font-inter text-base md:text-[20px] font-semibold">
                All Stations Activity
              </span>
              <button className="bg-green-500 font-inter text-white font-semibold py-1 px-3 md:py-2 md:px-4 rounded-lg shadow-lg hover:bg-green-600 flex items-center justify-center">
                <span className="mr-2">+</span> Add Station
              </button>
            </div>
            <StationGraph />
            <div className="ml-4 md:ml-[10px]">
              <span className="text-black text-base font-inter md:text-[20px] font-semibold">
                Stations Location
              </span>
              <StationMap />
            </div>
            <div className="text-black mt-[10px] ml-[15px] text-base md:text-[20px] font-inter font-semibold">
              All Stations
              <div className="flex flex-wrap justify-start ml-[15px] md:justify-between">
                {stations.length === 0 ? (
                  <div className="text-sm text-inter">
                    No stations available.
                  </div>
                ) : (
                  stations.map((station) => (
                    <StationCard
                      key={station._id}
                      stationImage={station.station_image || defaultStation}
                      stationName={station.station_name}
                      address={station.address}
                      ratingCount={station.rating_count}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

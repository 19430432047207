import React from 'react';

export const ReviewsIcon = ({ className }) => (
	<svg
		className={className}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.7344 9.245V12.3006C14.7344 12.4992 14.7268 12.6902 14.7038 12.8735C14.5281 14.936 13.3136 15.9596 11.0753 15.9596H10.7698C10.5788 15.9596 10.3955 16.0513 10.2809 16.2041L9.36422 17.4263C8.95935 17.9687 8.30239 17.9687 7.89753 17.4263L6.98085 16.2041C6.88154 16.0742 6.66002 15.9596 6.49196 15.9596H6.18641C3.74958 15.9596 2.52734 15.3562 2.52734 12.3006V9.245C2.52734 7.00679 3.55861 5.7922 5.61349 5.6165C5.79682 5.59358 5.9878 5.58594 6.18641 5.58594H11.0753C13.5122 5.58594 14.7344 6.80818 14.7344 9.245Z"
			stroke="white"
			strokeWidth="1.04167"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M17.7908 6.19032V9.2459C17.7908 11.4918 16.7595 12.6987 14.7046 12.8744C14.7275 12.6911 14.7352 12.5001 14.7352 12.3015V9.2459C14.7352 6.80907 13.5129 5.58683 11.0761 5.58683H6.18718C5.98857 5.58683 5.79759 5.59448 5.61426 5.6174C5.78995 3.56252 7.00455 2.53125 9.24276 2.53125H14.1317C16.5685 2.53125 17.7908 3.75349 17.7908 6.19032Z"
			stroke="white"
			strokeWidth="1.04167"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.3092 11.1241H11.316"
			stroke="white"
			strokeWidth="1.38889"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.63535 11.1241H8.64222"
			stroke="white"
			strokeWidth="1.38889"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.96152 11.1241H5.96839"
			stroke="white"
			strokeWidth="1.38889"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

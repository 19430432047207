import React from "react";
import RevenueCard from "v2/components/specific/DashboardWidgets/RevenueGenerationGraph";
import TotalCurrentEnergyCard from "v2/components/specific/DashboardWidgets/TotalCurrentEnergyGraph";
import ChargerCard from "v2/components/specific/DashboardWidgets/ChargerGraph";
import StationCard from "v2/components/specific/DashboardWidgets/StationGraph";
import BookingWidget from "v2/components/common/BookingWidget";
import { GreenArrowSvg } from "v2/assets/svgIcons/GreenArrowSvg";
import FilledContainer from "v2/components/specific/DashboardWidgets/ActiveChargingSessions";
import Customers from "v2/components/specific/DashboardWidgets/Customers";
import AverageSessionLength from "v2/components/specific/DashboardWidgets/AverageSessionLength";
import "v2/assets/customCSS/dashboard.css";
export default function Dashboard() {
  return (
    <div class=" bg-gray-50 flex-1 relative overflow-hidden overflow-y-auto">
      <div class="lg:h-auto mt-[32px] overflow-hidden">
        <div className="flex space-between container container1 mx-[32px] ">
          <div className="flex mb-[24px]">
            {/* the first chart */}
            <RevenueCard />
            {/* the second chart */}
            <TotalCurrentEnergyCard />
          </div>
          <div className="flex mb-[24px]">
            {/* the third graph */}
            <StationCard />
            {/* the fourth graph */}
            <ChargerCard />
          </div>
        </div>
        <div className="flex container">
          {/* Bookings container */}
          <div className="w-auto mx-[32px] mb-[52px] rounded-[16px] h-auto p-[16px] bg-white">
            <span className="text-[16px] pb-[16px]">Ongoing Bookings</span>
            <BookingWidget
              number="1"
              type="Type 2"
              date="12 Dec"
              time="3:30 pm"
              station="Rhodium Station"
              duration="30 minutes"
            />
            <BookingWidget
              number="2"
              type="ChAdeMO"
              date="12 Nov"
              time="1:00 pm"
              station="Rhodium Station"
              duration="30 minutes"
            />
            <BookingWidget
              number="3"
              type="GBT"
              date="8 Oct"
              time="12:00 am"
              station="Prestige Station"
              duration="30 minutes"
            />
            <BookingWidget
              number="4"
              type="Type 1"
              date="12 Oct"
              time="4:00 pm"
              station="Lawrence Station"
              duration="30 minutes"
            />
            <BookingWidget
              number="5"
              type="Type 1"
              date="12 Oct"
              time="4:00 pm"
              station="Lawrence Station"
              duration="30 minutes"
            />
            <div className="flex justify-center items-center">
              <button className="flex flex-row mt-4">
                <div className="text-green-500 ">View Statistics</div>
                <div className="p-[7px]">
                  <GreenArrowSvg />
                </div>
              </button>
            </div>
          </div>
          {/* the percentage container  */}
          <div className="w-auto rounded-lg h-auto mb-[24px]">
            <div className="w-auto container2 shadow-md rounded-[16px] h-[172px] bg-white mx-[24px] mb-[24px] p-[24px]">
              <FilledContainer percentage={75} />
            </div>
            <div className="w-auto container2 shadow-md rounded-[16px] h-[220px] bg-white mx-[24px] mb-[24px] p-[24px]">
              <Customers percentage={90} />
            </div>
            <div className="w-auto container2 shadow-md rounded-[16px] h-[172px] bg-white mx-[24px] mb-[24px] p-[24px]">
              <AverageSessionLength percentage={60} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

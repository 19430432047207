import React from "react";

export const ToiletIconSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66799 10.1683V12.9082C1.66799 13.3494 1.73862 13.7877 1.87891 14.2057L2.42363 15.8379L2.42266 15.8389C2.65003 16.52 3.28764 16.9796 4.00554 16.9796C4.68378 16.9506 5.26721 16.491 5.45395 15.8389L5.99769 14.2066V14.2057C6.13799 13.7877 6.20862 13.3494 6.20862 12.9082V10.1683C6.20862 9.56647 5.96964 8.98886 5.54392 8.56313C5.1182 8.13741 4.54058 7.89844 3.93781 7.89844C2.68389 7.89844 1.66799 8.91436 1.66799 10.1683Z"
        fill="#71CB80"
      />
      <path
        d="M6.20862 4.49249C6.20862 5.74641 5.1927 6.76233 3.93878 6.76233C2.68485 6.76233 1.66797 5.74641 1.66797 4.49249C1.66797 3.88971 1.90695 3.3121 2.33267 2.88638C2.75838 2.46065 3.336 2.22168 3.93878 2.22168C4.54058 2.22168 5.11819 2.46066 5.54392 2.88638C5.96965 3.31209 6.20862 3.88971 6.20862 4.49249Z"
        fill="#71CB80"
      />
      <path
        d="M15.0381 7.89844C13.8887 7.90231 12.923 8.76438 12.7905 9.90608L12.7886 9.85963C12.3425 11.5238 11.6556 13.0835 11.3208 13.7974C11.2144 14.0248 11.3295 14.2057 11.584 14.2057H12.9782L13.5229 15.8389L13.5219 15.8379C13.7483 16.52 14.386 16.9796 15.1048 16.9796C15.7831 16.9506 16.3665 16.491 16.5532 15.8379L17.097 14.2047H18.4912C18.7456 14.2047 18.8608 14.0238 18.7544 13.7964C18.4196 13.0824 17.7336 11.5246 17.2876 9.86238C17.2876 9.86045 17.2856 9.85851 17.2856 9.85851V9.85948C17.1318 8.73616 16.172 7.89844 15.0381 7.89844Z"
        fill="#71CB80"
      />
      <path
        d="M17.3102 4.4925C17.3102 5.74642 16.2933 6.7633 15.0394 6.7633C14.4376 6.7633 13.86 6.52432 13.4342 6.09764C13.0085 5.67191 12.7695 5.09431 12.7695 4.4925C12.7695 3.89069 13.0085 3.31308 13.4342 2.88735C13.8599 2.46163 14.4376 2.22266 15.0394 2.22266C16.2933 2.22266 17.3102 3.23857 17.3102 4.4925Z"
        fill="#71CB80"
      />
      <path
        d="M9.50328 1.66654C9.29042 1.66847 9.12013 1.84263 9.1211 2.05451V17.1509C9.12013 17.3637 9.29041 17.5369 9.50328 17.5389C9.60681 17.5398 9.7055 17.4992 9.77903 17.4257C9.8516 17.3531 9.89223 17.2534 9.89223 17.1509V2.05451C9.89223 1.95195 9.8516 1.85229 9.77903 1.77972C9.7055 1.70716 9.60681 1.66557 9.50328 1.66654Z"
        fill="#71CB80"
      />
    </svg>
  );
};

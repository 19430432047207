import React from "react";

export const CafeIconSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.605 16.2009L8.42489 12.5537L9.7148 14.3085L7.22858 17.6808C6.2764 18.9713 4.51448 17.6114 5.605 16.2009ZM8.89827 7.89524C9.16683 6.96066 9.0434 6.16207 8.03426 4.97576L5.56838 1.81179C5.21844 1.37343 4.39376 2.01722 4.73828 2.54817L6.71044 5.13924C7.08344 5.62244 6.39034 6.26189 6.00513 5.76422L3.96515 3.0849C3.58537 2.6205 2.80003 3.23391 3.19744 3.76486C3.77254 4.50268 4.66232 5.7078 5.23742 6.44563C5.6362 6.8782 5.0299 7.51909 4.63655 6.97947L2.61149 4.3175C2.31445 3.97318 1.82887 4.1873 1.70001 4.58949C1.56166 5.01916 1.90211 5.3331 2.13405 5.65138L4.39376 8.76472C5.09501 9.6313 5.87899 10.1449 6.79047 9.8541C6.93017 9.80925 7.10108 9.71811 7.28419 9.59803L13.3553 17.9572C13.6822 18.4071 14.3129 18.4548 14.7225 18.0917L14.8161 18.0093C15.2773 17.5998 15.3519 16.8519 14.9572 16.3687L8.52798 8.48984C8.71516 8.26705 8.85215 8.05583 8.89827 7.89524ZM10.6168 9.71811L11.6164 8.42474C10.3306 5.02495 15.0955 0.493824 17.4054 2.5771C20.2118 5.11031 16.0409 11.2155 13.1098 9.70653L11.9257 11.3109L10.6168 9.71811Z"
        fill="#71CB80"
      />
    </svg>
  );
};

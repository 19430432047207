import React from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const svgMarkup = `
<svg width="30px" height="30px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  <circle cx="50" cy="50" r="45" fill="none" stroke="#71CB80" strokeWidth="2"/>
  <circle cx="50" cy="50" r="35" fill="none" stroke="#71CB80" strokeWidth="2"/>
  <circle cx="50" cy="50" r="25" fill="none" stroke="#71CB80" strokeWidth="2"/>
  <circle cx="50" cy="50" r="15" fill="none" stroke="#71CB80" strokeWidth="2"/>
  <path d="M50 50 L50 5 A45 45 0 0 1 95 50 Z" fill="#71CB80" fill-opacity="0.3">
    <animateTransform
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      from="0 50 50"
      to="360 50 50"
      dur="4s"
      repeatCount="indefinite"/>
  </path>
  <circle cx="50" cy="50" r="5" fill="#71CB80"/>
</svg>
`;

const createSvgIcon = () => {
	return L.divIcon({
		className: 'svg-icon', // Define this class in your CSS to set additional styles
		html: svgMarkup,
	});
};

const CustomMarker = ({ position }) => {
	const map = useMap();

	// Add the SVG icon to the map
	const marker = L.marker(position, { icon: createSvgIcon() }).addTo(map);

	return null;
};

const stationsCom = [
	{ id: 1, name: '257 Stations', position: [49.505, -0.09] },
	{ id: 2, name: '24 Stations', position: [48.8566, 2.3522] }, // Paris, France
	{ id: 3, name: '122 Stations', position: [52.52, 13.405] }, // Berlin, Germany
	{ id: 4, name: '86 Stations', position: [40.7128, -74.006] }, // New York City, USA
	{ id: 5, name: '321 Stations', position: [35.6895, 139.6917] }, // Tokyo, Japan
	{ id: 6, name: '59 Stations', position: [-33.8688, 151.2093] }, // Sydney, Australia
	{ id: 7, name: '165 Stations', position: [55.7558, 37.6173] }, // Moscow, Russia
	{ id: 8, name: '98 Stations', position: [-23.5505, -46.6333] }, // São Paulo, Brazil
	{ id: 9, name: '204 Stations', position: [19.4326, -99.1332] }, // Mexico City, Mexico
	{ id: 10, name: '75 Stations', position: [34.0522, -118.2437] }, // Los Angeles, USA
];

const StationMap = () => {
	return (
		<div className="h-auto flex justify-center">
			<div className="relative w-full z-40 max-w-full mt-[10px] mr-[20px] ml-[5px] md:ml-[32px] lg:ml-4 rounded-md">
				<MapContainer
					center={[49.505, -0.09]}
					zoom={2}
					minZoom={3}
					maxZoom={18}
					scrollWheelZoom={true}
					className="h-[372px] w-full rounded-md"
				>
					<TileLayer
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					/>
					{stationsCom.map((station) => (
						<CustomMarker key={station.id} position={station.position} />
					))}
				</MapContainer>
				<div
					className="absolute top-0 left-0 h-full w-full"
					style={{ background: 'rgba(0, 0, 0, 0.5)' }}
				></div>
			</div>
		</div>
	);
};

export default StationMap;

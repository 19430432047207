import React, { useState } from "react";
import ChargerProfileSvg from "v2/assets/svgIcons/chargerProfilesvg";

const ChargerHeader = ({ charger }) => {
  const [isEnergyBased, setIsEnergyBased] = useState(true);

  const togglePricingModel = () => {
    setIsEnergyBased(!isEnergyBased);
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center space-x-3">
        <ChargerProfileSvg />
        <div>
          <h2 className="text-lg font-semibold">{charger.name}</h2>
          <p className="text-sm text-gray-500">
            {charger.power} |{" "}
            <span
              className={`font-bold ${
                charger.isActive ? "text-green-500" : "text-red-500"
              }`}
            >
              {charger.status}
            </span>
          </p>
        </div>
      </div>
      <div className="flex bg-white shadow rounded-lg gap-2  space-x-4 bg-white px-4 py-2 rounded-md shadow">
      <div className="text-gray-600 text-sm font-medium">Pricing Model
          <div className="text-sm text-black font-bold">
            {isEnergyBased ? "Energy Based Model" : "Fixed Price Model"}
          </div>
      </div>
        <button
          onClick={togglePricingModel}
          className="bg-gray-300 p-2 mt-1 mb-1 rounded-md gap-4 shadow items-center justify-center text-gray-400 hover:text-gray-500"
          aria-label="Edit"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.332 2.00004C11.5071 1.82494 11.715 1.68605 11.9438 1.59129C12.1725 1.49653 12.4177 1.44775 12.6654 1.44775C12.913 1.44775 13.1582 1.49653 13.387 1.59129C13.6157 1.68605 13.8236 1.82494 13.9987 2.00004C14.1738 2.17513 14.3127 2.383 14.4074 2.61178C14.5022 2.84055 14.551 3.08575 14.551 3.33337C14.551 3.581 14.5022 3.82619 14.4074 4.05497C14.3127 4.28374 14.1738 4.49161 13.9987 4.66671L4.9987 13.6667L1.33203 14.6667L2.33203 11L11.332 2.00004Z"
                stroke="#333333"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ChargerHeader;

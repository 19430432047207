import React from 'react';
import { useNavigate } from "react-router-dom";


const ButtonsBar = () => {

  const navigate = useNavigate();
  const handleAddChargerClick = () => {
    console.log('Add Charger button clicked');
    navigate('/chargers/emptyCharger')
  };
  return (
    <div className="flex justify-between items-center mb-4">
      <div className="relative inline-flex">
        <svg
          className="w-[8px] h-[4.8px] absolute top-0 right-0 mt-[17.59px] mr-4 pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 412 232"
        >
          <path
            d="M206 171.144L42.678 7.822c-4.686-4.686-12.284-4.686-16.97 0s-4.686 12.284 0 16.97L197.49 196.556c4.686 4.686 12.284 4.686 16.97 0L386.292 24.792c4.686-4.686 4.686-12.284 0-16.97s-12.284-4.686-16.97 0L206 171.144z"
            fill="#648299"
            fill-rule="nonzero"
          />
        </svg>
        <select className=" rounded-md text-gray-600 text-[12px] h-[40px] w-auto pl-5 pr-10 bg-gray-200 hover:border-gray-400 focus:outline-none appearance-none">
          <option>All Stations</option>
          <option>DHA Stations</option>
          <option>Defence Stations</option>
          <option>Dubai Stations</option>
        </select>
      </div>

      <button className="bg-green-500 hover:bg-green-700 h-[48px] w-[142px] text-white py-2 px-4 rounded-md"
      onClick={handleAddChargerClick}
      >
        + Add Charger
      </button>
    </div>
  );
};

export default ButtonsBar;

import React from "react";

// Define a BookingWidget component that accepts props for dynamic content
const BookingWidget = ({ number, type, date, time, station, duration }) => {
  return (
    <div className="flex items-center justify-between bg-white shadow rounded-lg w-400px h-80px p-4 mb-[8px]">
      <div className="flex items-center">
        <div className="bg-green-200 text-green-700 rounded-full h-12 w-12 flex items-center justify-center mr-4">
          {number}
        </div>
        <div>
          <div className="font-semibold">{type}</div>
          <div className="font-semibold text-sm text-gray-600">{`${date} at ${time}`}</div>
        </div>
      </div>
      <div className="text-left xl:ml-[60px] lg:ml-[60px]">
        <div className="text-[12px] text-gray-600">Booked at</div>
        <div className="text-[15px] font-semibold">{station}</div>
        <div className="text-[12px] text-gray-600">{`Booked for ${duration}`}</div>
      </div>
    </div>
  );
};

export default BookingWidget;

import React, { useState } from "react";
import Charger1Image from "../../../assets/images/Charger1.png";
import Charger2Image from "../../../assets/images/Charger2.png";
import Charger3Image from "../../../assets/images/Charger3.png";
import Charger4Image from "../../../assets/images/Charger4.png";
import Charger5Image from "../../../assets/images/Charger5.png";
import Charger6Image from "../../../assets/images/Charger6.png";

const chargersData = [
  {
    id: 1,
    image: Charger1Image,
    name: "Bijli Baba",
    types: ["CCS2", "CHAdeMO"],
    availability: "available",
  },
  {
    id: 2,
    image: Charger2Image,
    name: "Barq Raftar",
    types: ["CCS2", "Type 2"],
    availability: "not-available",
  },
  {
    id: 3,
    image: Charger3Image,
    name: "Barq Boost",
    types: ["CHAdeMO", "Type 2 AC"],
    availability: "available",
  },
  {
    id: 4,
    image: Charger4Image,
    name: "Barq Saaz",
    types: ["GB/T", "CCS"],
    availability: "available",
  },
  {
    id: 5,
    image: Charger5Image,
    name: "Energy Saaz",
    types: ["Type 2"],
    availability: "available",
  },
  {
    id: 6,
    image: Charger6Image,
    name: "Barq Hub",
    types: ["Type 1", "Type 2"],
    availability: "not-available",
  },
];

const availabilityStyles = {
  available: "text-green-600 bg-green-100",
  "not-available": "text-gray-600 bg-gray-100",
};

const getDotColor = (availability) => {
  return availability === "available" ? "bg-green-600" : "bg-gray-400";
};

const StationChargersWidget = () => {
  const [chargers, setChargers] = useState(chargersData);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-[16px] font-inter font-semibold mb-2">
        Station Chargers
      </h2>
      <ul>
        {chargers.length > 0 ? (
          chargers.map((charger) => (
            <li
              key={charger.id}
              className="flex items-center justify-between mb-1 p-2"
            >
              <div className="flex items-center">
                <img
                  src={charger.image}
                  alt={charger.name}
                  className="w-12 h-12 mr-4"
                />
                <div>
                  <div className="text-sm font-medium">{charger.name}</div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                {charger.types.map((type, index) => (
                  <div
                    key={index}
                    className={`flex items-center font-inter text-[10px] rounded-full px-2 py-1 mt-1 ${
                      charger.availability === "available"
                        ? "text-green-600 bg-green-100"
                        : "text-gray-600 bg-gray-100"
                    }`}
                  >
                    <span
                      className={`w-2 h-2 rounded-full mr-2 ${getDotColor(
                        charger.availability
                      )}`}
                    ></span>
                    {type}
                  </div>
                ))}
              </div>
            </li>
          ))
        ) : (
          <li className="text-center text-gray-500">No chargers available</li>
        )}
      </ul>
      <div className="text-center mt-4">
        <button className="text-green-600 text-[14px] font-inter">
          View All Chargers &gt;
        </button>
      </div>
    </div>
  );
};

export default StationChargersWidget;

import React from "react";

export const BellIconSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9116 14.748L18.913 14.7502C19.2233 15.2594 19.2775 15.8584 19.0721 16.4037C18.8637 16.9566 18.4355 17.3737 17.8839 17.555L17.8839 17.555L17.8811 17.5559C15.9942 18.1881 14.0079 18.5 12.0199 18.5C10.032 18.5 8.04657 18.1881 6.16098 17.5466L6.15998 17.5463C5.54292 17.3378 5.10185 16.9172 4.92065 16.4114L4.92067 16.4114L4.91923 16.4075C4.7272 15.8851 4.78757 15.3026 5.11856 14.7475C5.11879 14.7471 5.11902 14.7467 5.11925 14.7463L6.12778 13.0788L6.12784 13.0788L6.13183 13.0719C6.2675 12.8394 6.38148 12.5281 6.46118 12.2349C6.54078 11.9422 6.59994 11.6169 6.59994 11.35V8.82C6.59994 6.69904 7.83406 4.85737 9.62263 3.96767L9.76096 3.89886L9.83636 3.76402C10.2692 2.98983 11.0836 2.5 11.9899 2.5C12.9106 2.5 13.7038 2.97374 14.1347 3.73603L14.2122 3.87329L14.3545 3.94122C16.1781 4.81178 17.4399 6.67082 17.4399 8.82V11.35C17.4399 11.6169 17.4991 11.9421 17.5786 12.2356C17.6583 12.5303 17.7717 12.8417 17.9051 13.0768L17.905 13.0769L17.9117 13.088L18.9116 14.748Z"
        stroke="#34B44A"
      />
      <path
        d="M10.0952 20.6143C10.1457 20.6193 10.1963 20.6239 10.2471 20.6282C10.8286 20.6792 11.423 20.71 12.0197 20.71C12.607 20.71 13.1921 20.6791 13.7642 20.628L13.7642 20.628L13.7671 20.6277C13.8101 20.6237 13.8577 20.6197 13.9083 20.6156C13.4471 21.1572 12.7618 21.5 11.9997 21.5C11.3408 21.5 10.6921 21.2319 10.2395 20.7628L10.2308 20.7538L10.2217 20.7452C10.1779 20.7042 10.1357 20.6604 10.0952 20.6143Z"
        stroke="#34B44A"
      />
    </svg>
  );
};

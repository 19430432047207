import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import VerteLogo from 'v2/assets/images/verteLogo.png';
import StationsTab from 'v2/components/specific/NavigationBarWidgets/navBarStationLink.jsx';
import ChargersTab from 'v2/components/specific/NavigationBarWidgets/navBarChargerLink.jsx';
import { SearchbarIcon } from 'v2/assets/svgIcons/searchBarsvg';
import { PlusIconSvg } from "v2/assets/svgIcons/plusIconSvg";
import { DashboardIcon } from "v2/assets/svgIcons/dashboardsvg.jsx";
import { BookingsIcon } from "v2/assets/svgIcons/bookingssvg.jsx";
import { SessionsIcon } from "v2/assets/svgIcons/sessionssvg.jsx";
import { MonetizationIcon } from "v2/assets/svgIcons/monetizationsvg.jsx";
import { ReviewsIcon } from "v2/assets/svgIcons/reviewssvg.jsx";
import { UserManagementIcon } from "v2/assets/svgIcons/userManagementsvg.jsx";
import { HamBurgerIcon } from "v2/assets/svgIcons/hamBurger.jsx";

const navigationLinks = [
  { name: "Bookings", href: "/bookings", icon: <BookingsIcon /> },
  { name: "Charging Sessions", href: "/sessions", icon: <SessionsIcon /> },
  { name: "Monetization", href: "/monetization", icon: <MonetizationIcon /> },
  { name: "Reviews & Feedback", href: "/reviews", icon: <ReviewsIcon /> },
  { name: "User Management", href: "/users", icon: <UserManagementIcon /> },
];

export default function NavigationBar() {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeLink, setActiveLink] = useState("/dashboard"); // Initially set dashboard as active
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  // Additional style for when the sidebar is open
  const sidebarStyle = {
    zIndex: isSidebarOpen ? 50 : 1, // Ensures sidebar is above other content when open
  };
  return (
    <>
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="text-black p-4 md:hidden"
      >
        <HamBurgerIcon />
      </button>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-40 bg-black bg-opacity-50 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
      <div
        className={`fixed h-screen bg-gray-800 text-white min-w-[288px]  py-0 px-2 inset-y-0 left-0 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:relative md:translate-x-0 transition duration-900 ease-in-out z-50`}
        style={sidebarStyle}
      >
        <a
          href="/"
          className="flex items-center ml-[30px] h-20 w-full pt-[52px]"
        >
          <img src={VerteLogo} alt="LOGO" />
        </a>
        <div className="relative ml-[5px] mr-[5px] px-4">
          <div className="absolute inset-y-0 left-0 pl-8 flex items-center pointer-events-none">
            {<SearchbarIcon />}
          </div>
          <input
            type="text"
            id="search-bar"
            className="block w-full mt-[25px] pl-12 pr-3 py-2 border border-gray-600 rounded-md leading-5 bg-gray-700 text-gray-300 placeholder-gray focus:outline-none focus:bg-gray-500 focus:border-gray-300 focus:text-white"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <nav>
            <NavLink
              to="/dashboard"
              className={
                "flex ml-[20px] mr-[20px] items-center px-4 py-2 mt-5 rounded-md " +
                (activeLink === "/dashboard" ? "bg-green-500" : "text-gray-400")
              }
              onClick={() => setActiveLink("/")}
            >
              <span className="text-lg mr-3">
                <DashboardIcon />
              </span>
              Dashboard
            </NavLink>
            <StationsTab
              setActiveLink={setActiveLink}
              activeLink={activeLink}
            />
            <NavLink
              to="/dashboard/addStation"
              className={
                "flex ml-[20px] mr-[20px] items-center px-4 py-2 mt-5 rounded-md " +
                (activeLink === "/dashboard/addStation"
                  ? "bg-green-500"
                  : "text-gray-400")
              }
              onClick={() => setActiveLink("/dashboard/addStation")}
            >
              <span className="text-lg mr-3">
                <PlusIconSvg />
              </span>
              Add Station
            </NavLink>
            <ChargersTab
              setActiveLink={setActiveLink}
              activeLink={activeLink}
            />
            {navigationLinks.map((link) => (
              <NavLink
                key={link.name}
                to={link.href}
                className={
                  "flex ml-[20px] mr-[20px] items-center px-4 py-2 mt-5 rounded-md " +
                  (activeLink === link.href ? "bg-green-500" : "text-gray-400")
                }
                onClick={() => setActiveLink(link.href)}
              >
                <span className="text-lg mr-3">{link.icon}</span>
                {link.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import 'chart.js/auto';
import Chart from 'react-apexcharts';

import { GreenArrowSvg } from 'v2/assets/svgIcons/GreenArrowSvg';

const TotalCurrentEnergyCard = () => {
	const options = {
		series: [80], // Your gauge value goes here
		chart: {
			height: 265,
			type: 'radialBar',
		},
		plotOptions: {
			radialBar: {
				startAngle: -90,
				endAngle: 90,
				track: {
					background: '#D6F0DB', // Also setting the track background color
					strokeWidth: '100%', // Adjust track width to fit the gauge design
				},
				dataLabels: {
					name: {
						show: false,
					},
					value: {
						offsetY: -15,
						fontSize: '22px',
						color: undefined,
						formatter: function (val) {
							return val + ' MV';
						},
					},
				},
			},
		},
		fill: {
			type: 'solid',
			colors: ['#71CB80'],
		},
		// stroke: {
		//   lineCap: "round", // Round the ends of the gauge
		// },
		tooltip: {
			enabled: true,
			theme: 'light',
			style: {
				fontSize: '12px',
			},
			marker: {
				show: false, // Hide the marker
			},
			y: {
				formatter: function (val) {
					return val + '%';
				},
			},
		},
		legend: {
			show: false, // This disables the legend
		},
	};
	return (
		<div className="w-[214px] h-[325px] p-6 mr-[24px] bg-white rounded-lg shadow-md max-w-sm">
			<div className="text-[16px] font-inter font-semibold text-gray-700">
				Total Current Energy Usage
			</div>
			<div id="chart" className="relative w-[166px] h-[130px] mb-[20px]">
				<Chart
					options={options}
					series={options.series}
					type="radialBar"
					height={260}
				/>
				{/* Labels for 0 GW and 2.3 GW positioned absolutely */}
				<div className="absolute text-black top-full left-0 transform -translate-y-1/2 text-[8px]">
					0 GW
				</div>
				<div className="absolute top-full text-black right-0 transform -translate-y-1/2 text-[8px]">
					2.3 GW
				</div>
			</div>
			<div className="flex flex-row text-green-500 text-[14px]">
				↑ 40%{' '}
				<span className="text-black text-[14px] ml-1"> vs energy used</span>
			</div>
			<div className="text-gray-600 text-[12px]">+ 2.3MV over February</div>
			<div className="flex justify-center items-center">
				<button className="flex flex-row mt-[20px]">
					<div className="text-green-500 ">View Statistics</div>
					<div className="p-[7px]">
						<GreenArrowSvg />
					</div>
				</button>
			</div>
		</div>
	);
};

export default TotalCurrentEnergyCard;

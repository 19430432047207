import React, { useState } from "react";
import { DropDownIcon } from "v2/assets/svgIcons/dropDownSvg";

const AddChargerPopup = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    chargerName: "",
    powerCapacity: "",
    chargerType: "",
    connectorTypes: [""],
    stationName: "",
    pricingModel: "",
    macAddress: "",
    wifiSSID: "",
    wifiPassword: "",
  });

  const toggleModal = () => setShowModal(!showModal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleConnectorChange = (index, value) => {
    const newConnectorTypes = [...formData.connectorTypes];
    newConnectorTypes[index] = value;
    setFormData({ ...formData, connectorTypes: newConnectorTypes });
  };

  const addMoreConnector = () => {
    setFormData({
      ...formData,
      connectorTypes: [...formData.connectorTypes, ""],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("YOUR_BACKEND_API_URL", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      console.log(formData);
      if (response.ok) {
        // Handle successful submission
        console.log("Charger added successfully");
        toggleModal(); // Close the modal on successful submission
      } else {
        // Handle errors
        console.error("Failed to add charger");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      
      <button
        className="bg-green-500 hover:bg-green-600 text-white ml-[120px] font-bold py-2 px-4 rounded transition duration-150"
        onClick={toggleModal}
      >
        + Add Charger
      </button>

      {showModal && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-50 z-40 flex justify-center items-center"
          style={{ backdropFilter: "blur(18px)" }}
        >
          <div className="bg-white p-5 rounded-[8px] shadow-lg max-w-[1200px] max-h-[686px] w-full mx-4">
            <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold left-0">
              <span className="text-green-500">"Add Charger" </span> to Your Station
            </h2>
            <button
                  type="button"
                  className="hover:bg-gray-100 flex text-gray-500 py-1 px-3 rounded-[8px] transition duration-150"
                  onClick={toggleModal}
                >
                  x
                </button>
            </div>
            <div className="flex relative items-center justify-between col-span-2">
              <h2 className="">Charger Details</h2>
              <button
                type="button"
                className="bg-[#34B44A] hover:bg-green-600 text-white font-bold py-2 px-4 rounded-[8px] transition duration-150"
                onClick={addMoreConnector}
                disabled={formData.connectorTypes.length >= 3}
              >
                + Add More Connector
              </button>
            </div>

            <form onSubmit={handleSubmit} className=" space-y-4 mt-4">
              <div className="flex flex-wrap  space-x-4">
                <div className="flex-1 min-w-[150px]">
                  <label
                    className="block text-[#34B44A] text-sm mb-2"
                    htmlFor="chargerName"
                  >
                    Charger Name
                  </label>
                  <input
                    type="text"
                    id="chargerName"
                    name="chargerName"
                    className="shadow border rounded-[8px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Charger Name"
                    value={formData.chargerName}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex-1 min-w-[150px]">
                  <label
                    className="block text-[#34B44A] text-sm mb-2"
                    htmlFor="powerCapacity"
                  >
                    Power Capacity
                  </label>
                  <input
                    type="text"
                    id="powerCapacity"
                    name="powerCapacity"
                    className="shadow border rounded-[8px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Power Capacity"
                    value={formData.powerCapacity}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex-1 min-w-[150px] relative">
                  <label
                    className="block text-[#34B44A] text-sm mb-2"
                    htmlFor="chargerType"
                  >
                    Charger Type
                  </label>
                  <div className="relative">
                    <select
                      id="chargerType"
                      name="chargerType"
                      className="block appearance-none w-full bg-white text-gray-700 border border-gray-200 hover:border-gray-500 pl-4 py-2 pr-8 rounded-[8px] shadow leading-tight focus:outline-none focus:shadow-outline"
                      value={formData.chargerType}
                      onChange={handleChange}
                    >
                      <option value="">Charger Type</option>
                      <option value="AC Charger">AC Charger</option>
                      <option value="DC Charger">DC Charger</option>
                    </select>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <DropDownIcon />
                    </span>
                  </div>
                </div>

                <div className="flex flex-col space-y-4">
                  {formData.connectorTypes.map((connectorType, index) => (
                    <div
                      key={index}
                      className="flex-1 flex-col min-w-[250px] relative"
                    >
                      <label
                        className="block text-[#34B44A] text-sm mb-2"
                        htmlFor={`connectorType${index}`}
                      >
                        Connector Type
                      </label>
                      <div className="relative">
                        <select
                          id={`connectorType${index}`}
                          name={`connectorType${index}`}
                          className="block w-full appearance-none bg-white border border-gray-200 hover:border-gray-500 px-4 py-2 pr-8 rounded-[8px] shadow leading-tight focus:outline-none focus:shadow-outline"
                          value={connectorType}
                          onChange={(e) =>
                            handleConnectorChange(index, e.target.value)
                          }
                        >
                          <option value="">Connector Type</option>
                          <option value="Type 1">Type 1</option>
                          <option value="CHAdeMO">CHAdeMO</option>
                          <option value="Type 2">Type 2</option>
                          <option value="CCS2">CCS2</option>
                        </select>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                          <DropDownIcon />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <h2 className="font mt-4">Select Station & Pricing Model</h2>
              <div className="flex flex-wrap space-x-4 mt-2">
                <div className="flex-1 min-w-[150px]">
                  <label
                    className="block text-[#34B44A] text-sm mb-2"
                    htmlFor="stationName"
                  >
                    Select Station
                  </label>
                  <div className="relative">
                    <select
                      id="stationName"
                      name="stationName"
                      className="block w-full appearance-none bg-white border border-gray-200 hover:border-gray-500 px-4 py-2 pr-8 rounded-[8px] shadow leading-tight focus:outline-none focus:shadow-outline"
                      value={formData.stationName}
                      onChange={handleChange}
                    >
                      <option value="">Select Station</option>
                      <option value="Station 1">Station 1</option>
                      <option value="Station 2">Station 2</option>
                    </select>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <DropDownIcon />
                    </span>
                  </div>
                </div>

                <div className="flex-1 min-w-[150px]">
                  <label
                    className="block text-[#34B44A] text-sm mb-2"
                    htmlFor="pricingModel"
                  >
                    Select Pricing Model
                  </label>
                  <div className="relative">
                    <select
                      id="pricingModel"
                      name="pricingModel"
                      className="block w-full appearance-none bg-white border border-gray-200 hover:border-gray-500 px-4 py-2 pr-8 rounded-[8px] shadow leading-tight focus:outline-none focus:shadow-outline"
                      value={formData.pricingModel}
                      onChange={handleChange}
                    >
                      <option value="">Select Pricing Model</option>
                      <option value="Bijli Baba Pricing Model">
                        Bijli Baba Pricing Model
                      </option>
                      <option value="Barq Saaz">Barq Saaz</option>
                      <option value="Barq Boost">Barq Boost</option>
                      <option value="Energy Saaz">Energy Saaz</option>
                    </select>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                      <DropDownIcon />
                    </span>
                  </div>
                </div>
              </div>

              <h2 className="font mt-4">Charger Configuration</h2>

              <div className="flex flex-row gap-[16px]">
                <div className="flex-1 min-w-[150px]">
                  <label
                    className="block text-[#34B44A] text-sm mb-2"
                    htmlFor="macAddress"
                  >
                    MAC Address
                  </label>
                  <input
                    type="text"
                    id="macAddress"
                    name="macAddress"
                    className="shadow border rounded-[8px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="MAC Address"
                    value={formData.macAddress}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex-1 min-w-[150px]">
                  <label
                    className="block text-[#34B44A] text-sm mb-2"
                    htmlFor="wifiSSID"
                  >
                    Select Available Wifi SSID
                  </label>
                  <div className="relative">
                    <select
                      id="wifiSSID"
                      name="wifiSSID"
                      className="block w-full appearance-none bg-white text-gray-700 border border-gray-200 hover:border-gray-500 px-4 py-2 pr-8 rounded-[8px] shadow leading-tight focus:outline-none focus:shadow-outline"
                      value={formData.wifiSSID}
                      onChange={handleChange}

                    >
                      <option className="bg-black" value="">Wifi SSID</option>
                      <option value="SSID1">SSID1</option>
                      <option value="SSID2">SSID2</option>
                    </select>
                    <span className="absolute inset-y-0  right-0 flex items-center pr-4 pointer-events-none">
                      <DropDownIcon />
                    </span>
                  </div>
                </div>
                

                <div className="flex-1 min-w-[150px]">
                  <label
                    className="block text-[#34B44A] text-sm mb-2"
                    htmlFor="wifiPassword"
                  >
                    Password
                  </label>

                  <input
                    type="password"
                    id="wifiPassword"
                    name="wifiPassword"
                    className="shadow border rounded-[8px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Password"
                    value={formData.wifiPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex justify-end space-x-4 mt-4">
                
                <button
                  type="submit"
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-[8px] transition duration-150"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddChargerPopup;

import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { StationsIcon } from "../../../assets/svgIcons/stationssvg.jsx";
//this tab is used to define all the    
const StationsTab = ({ setActiveLink, activeLink }) => {
  const [isStationsExpanded, setIsStationsExpanded] = useState(false);
  const stationsRef = useRef(null); // Ref for the stations container
  const [stations, setStations] = useState([
    {
      name: "Rhodium Station",
      href: "/dashboard/stations/rhodium",
      expanded: false,
      chargers: ["Naveed’s Charger", "Naveed’s Green Wire"],
    },
    {
      name: "Verte Station by Rhodium Tech",
      href: "/dashboard/stations/verte",
      expanded: false,
      chargers: ["Naveed’s Charger", "Naveed’s Green Wire"],
    },
  ]);
  const toggleStationExpansion = (index) => {
    setStations((stations) =>
      stations.map((station, i) => ({
        ...station,
        expanded: i === index ? !station.expanded : false,
      }))
    );
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (stationsRef.current && !stationsRef.current.contains(event.target)) {
        setIsStationsExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={stationsRef}>
      <NavLink
        to="/dashboard/stations"
        className={`flex ml-[20px] mr-[20px] items-center px-4 py-2 mt-5 bg-[#34B44A33] rounded-md ${
          activeLink.startsWith("/dashboard/stations")
            ? "bg-green-500"
            : "text-gray-400"
        }`}
        onClick={() => {
          setActiveLink("/dashboard/stations");
          setIsStationsExpanded(!isStationsExpanded);
        }}
      >
        <span className="text-lg mr-3">
          <StationsIcon />
        </span>
        Stations
        <span className="ml-auto">
          {isStationsExpanded ? (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 15l7-7 7 7"
              />
            </svg>
          ) : (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          )}
        </span>
      </NavLink>
      {isStationsExpanded && (
        <div className="ml-[20px] mr-[20px] rounded-md">
          {stations.map((station, index) => (
            <React.Fragment key={index}>
              <div
                className="rounded-md flex bg-[#34B44A33] items-center pl-[48px] pr-[10px] py-2 mt-2 justify-between "
                onClick={() => toggleStationExpansion(index)}
              >
                {station.name.length > 15
                  ? `${station.name.substring(0, 15)}...`
                  : station.name}
                <span>
                  {station.expanded ? (
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </span>
              </div>
              {station.expanded && (
                <div className="rounded-md">
                  {station.chargers.map((charger, chargerIndex) => (
                    <button
                      key={chargerIndex}
                      className="block w-full h-[40px] text-left rounded-md pl-[48px] bg-[#333333] items-center px-12 py-2 mt-2 justify-between hover:bg-[#444444] transition-colors duration-200"
                      onClick={() => {
                        /* Handle button click here */
                      }}
                    >
                      {charger.length > 15
                        ? `${charger.substring(0, 14)}...`
                        : charger}
                    </button>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
export default StationsTab;

import React from "react";

export const TyreShopIconSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2C5.59001 2 2 5.59001 2 10C2 14.41 5.59001 18 10 18C14.4134 18 18 14.41 18 10C18 5.59001 14.4134 2 10 2ZM11.7806 15.9622C10.4739 16.3579 8.98807 16.2982 7.70007 15.7793C6.41581 15.2678 5.3257 14.327 4.62751 13.1286C4.07498 12.1841 3.78003 11.1014 3.78003 10V9.9403C3.79498 8.54029 4.26539 7.22239 5.13529 6.13228C5.8857 5.18773 6.87501 4.48954 7.99876 4.11247C8.64089 3.89226 9.31291 3.78024 10.0036 3.78024C10.6905 3.78024 11.3625 3.89226 12.0047 4.11247C13.1396 4.49701 14.1364 5.20267 14.8831 6.15839C15.7492 7.24482 16.2122 8.56271 16.2234 9.95525V10C16.2234 11.2955 15.8239 12.5387 15.0735 13.5953C14.2746 14.7302 13.106 15.5665 11.7806 15.9622Z"
        fill="#71CB80"
      />
      <path
        d="M11.6009 10.0004C11.6009 10.8804 10.8843 11.597 10.0009 11.597C9.12093 11.597 8.4043 10.8804 8.4043 10.0004C8.4043 9.12039 9.12093 8.40372 10.0009 8.40372C10.8843 8.40372 11.6009 9.12039 11.6009 10.0004Z"
        fill="#71CB80"
      />
      <path
        d="M6.82731 8.07674C6.82731 7.35303 6.47298 6.67944 5.90096 6.26025C5.81869 6.35254 5.73633 6.448 5.65731 6.54679C4.88062 7.52344 4.46061 8.70011 4.44727 9.94678V10.0001C4.44727 10.1091 4.45068 10.2182 4.46053 10.3272C4.49634 10.3287 4.53036 10.3334 4.5673 10.3334C5.81397 10.3334 6.82731 9.32011 6.82731 8.07674Z"
        fill="#71CB80"
      />
      <path
        d="M6.27498 12.0665C5.8234 12.0665 5.38916 12.2026 5.01953 12.4521C5.07926 12.568 5.13883 12.6806 5.205 12.7932C5.82837 13.8632 6.80168 14.7032 7.94832 15.1598C8.0673 15.2094 8.18962 15.2522 8.31519 15.2887C8.45671 14.9904 8.53499 14.6658 8.53499 14.3265C8.53499 13.0831 7.52165 12.0665 6.27498 12.0665Z"
        fill="#71CB80"
      />
      <path
        d="M11.0645 14.6335C11.0645 14.9052 11.1195 15.171 11.2145 15.4226C11.3401 15.3928 11.4656 15.3599 11.5878 15.3235C12.7777 14.9702 13.8211 14.2202 14.5277 13.2102C14.6044 13.1035 14.6778 12.9935 14.7445 12.8802L14.7449 12.8795C14.3464 12.555 13.8482 12.3735 13.3245 12.3735C12.0778 12.3735 11.0645 13.3868 11.0645 14.6335Z"
        fill="#71CB80"
      />
      <path
        d="M15.5583 9.95681C15.5483 8.71348 15.1349 7.54348 14.3649 6.57347C14.2882 6.47345 14.2049 6.38015 14.1216 6.2868C13.5683 6.70347 13.2383 7.36013 13.2383 8.07676C13.2383 9.32013 14.2515 10.3334 15.4983 10.3334H15.5483C15.5549 10.2234 15.5583 10.1101 15.5583 10.0001V9.95681Z"
        fill="#71CB80"
      />
      <path
        d="M10.0037 6.37364C10.9758 6.37364 11.8149 5.76231 12.127 4.87364C12.0169 4.82363 11.9036 4.78363 11.7903 4.74359C11.217 4.54698 10.6136 4.44696 10.0037 4.44696C9.3903 4.44696 8.78695 4.54698 8.21696 4.74359C8.1036 4.78029 7.98698 4.82363 7.87695 4.87364C8.18905 5.76231 9.02848 6.37364 10.0037 6.37364Z"
        fill="#71CB80"
      />
    </svg>
  );
};

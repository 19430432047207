import React from "react";
import BookingWidget from "v2/components/specific/AddStationWidgets/BookingWidget";
import StationChargersWidget from "v2/components/specific/AddStationWidgets/ChargerWidget";
import RevenueChart from "v2/components/specific/AddStationWidgets/RevenueGraph";
import { PencilIconSvg } from "v2/assets/svgIcons/pencilSvg";
import { ClockIconSvg } from "v2/assets/svgIcons/clockSvg";
import { StationImageSvg } from "v2/assets/svgIcons/stationImageSvg.jsx";
import { ATMIconSvg } from "v2/assets/svgIcons/ATMsvg";
import { CafeIconSvg } from "v2/assets/svgIcons/cafeSvg";
import { CarWashIconSvg } from "v2/assets/svgIcons/carWashSvg";
import { MechanicIconSvg } from "v2/assets/svgIcons/mechanicSvg";
import { ParkingIconSvg } from "v2/assets/svgIcons/parkingSvg";
import { StoreIconSvg } from "v2/assets/svgIcons/storeSvg";
import { ToiletIconSvg } from "v2/assets/svgIcons/toiletSvg";
import { TyreShopIconSvg } from "v2/assets/svgIcons/tyreShopSvg";
import "../../assets/customCSS/station.css";
export default function StationDisplay() {
  //amenities with their icons
  const facilities = [
    { name: "Cafe", icon: <CafeIconSvg /> },
    { name: "Car Wash", icon: <CarWashIconSvg /> },
    { name: "Toilet", icon: <ToiletIconSvg /> },
    {
      name: "Parking",
      icon: <ParkingIconSvg />,
    },
    {
      name: "Store",
      icon: <StoreIconSvg />,
    },
    {
      name: "Mechanic",
      icon: <MechanicIconSvg />,
    },
    {
      name: "ATM",
      icon: <ATMIconSvg />,
    },
    { name: "Tyre Shop", icon: <TyreShopIconSvg /> },
  ];
  return (
    <>
      <div className="flex h-screen">
        <div className="w-full md:h-screen bg-white relative overflow-hidden overflow-y-auto">
          <div className="lg:h-auto m-8 mt-2 overflow-hidden">
            <div className="flex items-center justify-between">
              <div className="flex mb-[32px] items-center">
                <div>
                  <StationImageSvg />
                </div>
                <div className="ml-4">
                  <h2 className="text-lg font-semibold font-inter">
                    Rhodium Gulberg Station
                  </h2>
                  <div className="flex items-center font-inter text-sm text-black">
                    <span className="mr-2">
                      <ClockIconSvg />
                    </span>
                    Mon-Fri (9AM-9PM){"  "}Sat-Sun (9AM-5PM)
                  </div>
                </div>
              </div>
              <div className="flex mb-[32px] space-x-2">
                <button className="bg-custom-green rounded-lg font-inter hover:bg-custom-green text-white py-2 px-4 flex items-center">
                  <span className="mr-2">+</span> Add Charger
                </button>
                <button className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded flex items-center">
                  Edit Station Details{" "}
                  <span className="ml-2">
                    <PencilIconSvg />
                  </span>
                </button>
              </div>
            </div>
            <h1 className="text-[16px] font-inter font-semibold ">
              Facilities
            </h1>
            <div className="flex flex-wrap items-center justify-between space-x-4">
              {facilities.map((facility) => (
                <div
                  key={facility.name}
                  className="flex my-3 items-center text-[14px] justify-center space-x-2 p-2 bg-white border rounded-lg shadow-sm flex-grow"
                >
                  <span className="text-black">{facility.name}</span>
                  {facility.icon}
                </div>
              ))}
            </div>
            <div className="flex items-center justify-between mb-[32px]">
              <div>
                <h2 className="text-black font-medium  text-1xl font-inter">
                  Revenue Statistics
                </h2>
                <div className="text-3xl font-bold text-custom-green font-inter">
                  $149,330
                  <span className="text-[16px] text-gray-300 font-inter">
                    .35
                  </span>
                </div>
              </div>
              <div>
                <select
                  id="Charger"
                  className="w-[110px] h-[40px] bg-gray-100 text-center text-neutral-500 text-sm font-bold font-['Roboto'] tracking-tight rounded-[9px] focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="This Week">This Week</option>
                  <option value="Today">Today</option>
                </select>
              </div>
            </div>
            <RevenueChart />
            <div className="flex justify-between p-2 flex-grow flex-row-to-col">
              <div className="flex-[2_1_0%] mr-2 flex-child">
                <BookingWidget />
              </div>
              <div className="flex-[1_1_0%] ml-2 flex-child">
                <StationChargersWidget />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import BookingsForSpecificCharger from "../../components/specific/ChargerWidgets/bookingsForSpecificCharger";
import CurrentEnergyCard from "v2/components/specific/ChargerWidgets/chargerCurrentEnergyCard";
import ChargerHeader from "v2/components/specific/ChargerWidgets/chargerHeader";
import ConnectorStatusCard from "v2/components/specific/ChargerWidgets/connectorStatus";

const SpecificCharger = () => {
  const chargers = [
    { type: "CHAdeMO", isActive: false },
    { type: "Type-1", isActive: false },
    { type: "CCS2", isActive: false },
  ];
  return (
    <div className="p-[32px] overflow-y-auto">
      <ChargerHeader
        charger={{
          name: "Vertecharge",
          power: "30KW",
          status: "Active",
          isActive: true,
        }}
      />
      <div className="boarder-gray grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[16px] mt-[32px] flex-row">
        {chargers.map((charger, index) => (
          <ConnectorStatusCard
            type="CHAdEMO"
            isActive={false}
            icon={
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 15.375C12.4525 15.375 13.625 16.5475 13.625 18C13.625 19.4525 12.4525 20.625 11 20.625C9.5475 20.625 8.375 19.4525 8.375 18C8.375 16.5475 9.5475 15.375 11 15.375ZM11 12.75C8.095 12.75 5.75 15.095 5.75 18C5.75 20.905 8.095 23.25 11 23.25C13.905 23.25 16.25 20.905 16.25 18C16.25 15.095 13.905 12.75 11 12.75ZM25 15.375C26.4525 15.375 27.625 16.5475 27.625 18C27.625 19.4525 26.4525 20.625 25 20.625C23.5475 20.625 22.375 19.4525 22.375 18C22.375 16.5475 23.5475 15.375 25 15.375ZM25 12.75C22.095 12.75 19.75 15.095 19.75 18C19.75 20.905 22.095 23.25 25 23.25C27.905 23.25 30.25 20.905 30.25 18C30.25 15.095 27.905 12.75 25 12.75ZM21.71 11.7175L19.2425 9.25L21.71 6.7825L20.4675 5.54L18 8.0075L15.5325 5.54L14.29 6.7825L16.7575 9.25L14.29 11.7175L15.5325 12.96L18 10.4925L20.4675 12.96L21.71 11.7175ZM21.71 29.2175L19.2425 26.75L21.71 24.2825L20.4675 23.04L18 25.5075L15.5325 23.04L14.29 24.2825L16.7575 26.75L14.29 29.2175L15.5325 30.46L18 27.9925L20.4675 30.46L21.71 29.2175ZM31.545 6.9225L32.875 5.6275L30.3725 3.125L29.0775 4.455C26.05 1.9875 22.2 0.5 18 0.5C13.8 0.5 9.95 1.9875 6.9225 4.455L5.6275 3.125L3.125 5.6275L4.455 6.9225C1.9875 9.95 0.5 13.8 0.5 18C0.5 27.625 8.375 35.5 18 35.5C27.625 35.5 35.5 27.625 35.5 18C35.5 13.8 34.0125 9.95 31.545 6.9225ZM18 32C10.2825 32 4 25.7175 4 18C4 10.2825 10.2825 4 18 4C25.7175 4 32 10.2825 32 18C32 25.7175 25.7175 32 18 32Z"
                  fill="#71CB80"
                />
              </svg>
            }
          />
        ))}
      </div>

      <div className="grid grid-cols-3 mt-[24px] gap-[16px]">
        <div className="col-span-2">
          <CurrentEnergyCard />
        </div>
        <div className="col-span-1">
          {/* total power consumption chart */}
          <CurrentEnergyCard />
        </div>
      </div>
      <div className="mt-[24px] shadow">
        {/* bookings chart showing the charging summary of each connector  */}
        <BookingsForSpecificCharger />
      </div>
    </div>
  );
};
export default SpecificCharger;

import React from "react";

export const ATMIconSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6372 6.60047C17.6372 7.10463 17.2247 7.51713 16.7205 7.51713H3.27604C2.77187 7.51713 2.35938 7.10463 2.35938 6.60047V6.59283C2.35938 4.84352 3.77257 3.43033 5.52187 3.43033H14.467C16.2163 3.43033 17.6372 4.85116 17.6372 6.60047Z"
        fill="#71CB80"
      />
      <path
        d="M2.35938 9.57975V13.4068C2.35938 15.1561 3.77257 16.5693 5.52187 16.5693H14.467C16.2163 16.5693 17.6372 15.1485 17.6372 13.3992V9.57975C17.6372 9.07559 17.2247 8.66309 16.7205 8.66309H3.27604C2.77187 8.66309 2.35938 9.07559 2.35938 9.57975ZM6.94271 14.0103H5.41493C5.10174 14.0103 4.84201 13.7506 4.84201 13.4374C4.84201 13.1242 5.10174 12.8645 5.41493 12.8645H6.94271C7.2559 12.8645 7.51562 13.1242 7.51562 13.4374C7.51562 13.7506 7.2559 14.0103 6.94271 14.0103ZM11.908 14.0103H8.85243C8.53924 14.0103 8.27951 13.7506 8.27951 13.4374C8.27951 13.1242 8.53924 12.8645 8.85243 12.8645H11.908C12.2212 12.8645 12.4809 13.1242 12.4809 13.4374C12.4809 13.7506 12.2212 14.0103 11.908 14.0103Z"
        fill="#71CB80"
      />
    </svg>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import 'chart.js/auto';
import ApexCharts from 'apexcharts';
import { GreenArrowSvg } from 'v2/assets/svgIcons/GreenArrowSvg';

const StationCard = () => {
  const chartRef = useRef(null); // useRef to reference the chart container div
  const [chartOptions, setChartOptions] = useState(getChartOptions(0, 0, 0)); // Initialize with default options

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://verte-charge-platforms-backend-test.vertecharge.com/station/status-summary",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "access-control-allow-credentials": true,
            },
            credentials: "include", // Include credentials if needed
          }
        );

        if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		  }
		
        const data = await response.json();
		console.log('API response data:', data);


        const { total_stations, active_stations, inactive_stations } = data;

        // Update the chart options with the fetched data
        const options = getChartOptions(
          total_stations,
          active_stations,
          inactive_stations
        );
        setChartOptions(options);

        // Render the chart with the updated options
        const chart = new ApexCharts(chartRef.current, options);
        chart.render().then(() => {
          // Once the chart is rendered, find the text element containing the total stations
          const totalLabel = Array.from(
            chartRef.current.querySelectorAll(".apexcharts-text")
          ).find((el) => el.textContent === `${total_stations}`);
          // Apply bold font weight to the total stations text element
          if (totalLabel) {
            totalLabel.setAttribute("font-weight", "bold");
            totalLabel.setAttribute("font-size", "26px"); // Set font size to 26px
          }
        });

        // Clean up the chart instance when the component unmounts
        return () => {
          if (chart && typeof chart.destroy === "function") {
            chart.destroy();
          }
        };
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // The empty array ensures this effect runs only once on mount

  return (
    <div className="w-[214px] h-[325px] mr-[24px] bg-white rounded-lg shadow-md max-w-sm">
      <div ref={chartRef}></div>
      <div className="flex justify-center items-center">
        <button className="flex flex-row mt-[26px]">
          <div className="text-green-500 ">View All Stations</div>
          <div className="p-[7px]">
            <GreenArrowSvg />
          </div>
        </button>
      </div>
    </div>
  );
};

const getChartOptions = (totalStations, activeStations, nonActiveStations) => {
  const activePercentage = (activeStations / totalStations) * 100;
  const nonActivePercentage = (nonActiveStations / totalStations) * 100;

  return {
    series: [activeStations, nonActiveStations],
    colors: ['#333333', '#A6DAB2'],
    chart: {
      height: '380px',
      width: '100%',
      type: 'radialBar',
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          show: true, // Set this to true to enable custom labels
          name: {
            offsetY: -10, // Adjust according to your needs for the label
          },
          value: {
            fontSize: '26px',
            offsetY: 0, // Adjust this as necessary to vertically center
            align: 'center',
            color: '#333', // Use undefined to use the default color
            formatter: function (val) {
              return `${(val / 100) * totalStations} stations`;
            },
          },
          total: {
            show: true,
            label: 'Stations', // The first line of text you want to display
            formatter: function (w) {
              // You can calculate total or any other logic you need
              return `${totalStations}`;
            },
            fontSize: '10px',
            fontFamily: 'Inter, sans-serif',
            align: 'center',
          },
        },
        hollow: {
          margin: 0,
          size: '40%',
        },
        stroke: {
          lineCap: 'round', // Make ends of the bar round
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 4,
      padding: {
        top: -23,
        bottom: -20,
      },
    },
    labels: ['Active', 'Non-Active'],
    legend: {
      show: true,
      position: 'bottom',
      fontFamily: 'Inter, sans-serif',
      horizontalAlign: 'center', // Ensure the legend is centered
      formatter: function (seriesName, opts) {
        return [seriesName]; // This will ensure each legend label is on its own line
      },
      itemMargin: {
        vertical: 5, // Adjust spacing between legend items
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        formatter: function (value) {
          return value + '%';
        },
      },
    },
  };
};

export default StationCard;

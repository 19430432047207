import React from "react";

export const UpArrowSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5V16.875M10 5L5.625 9.375M10 5L14.375 9.375"
        stroke="#34B44A"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

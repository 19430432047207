import React from "react";

export const ParkingIconSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0013 1.66666C5.41797 1.66666 1.66797 5.41666 1.66797 9.99999C1.66797 14.5833 5.41797 18.3333 10.0013 18.3333C14.5846 18.3333 18.3346 14.5833 18.3346 9.99999C18.3346 5.41666 14.5846 1.66666 10.0013 1.66666ZM10.0013 16.6667C6.33464 16.6667 3.33464 13.6667 3.33464 9.99999C3.33464 6.33332 6.33464 3.33332 10.0013 3.33332C13.668 3.33332 16.668 6.33332 16.668 9.99999C16.668 13.6667 13.668 16.6667 10.0013 16.6667Z"
        fill="#71CB80"
      />
      <path
        d="M10.9746 5.8335H7.22461V9.16683V11.6668V14.1668H8.89128V11.6668H10.9746C12.5579 11.6668 13.8913 10.3335 13.8913 8.75016C13.8913 7.16683 12.5579 5.8335 10.9746 5.8335ZM10.9746 10.0002H8.89128V9.16683V7.50016H10.9746C11.6413 7.50016 12.2246 8.0835 12.2246 8.75016C12.2246 9.41683 11.6413 10.0002 10.9746 10.0002Z"
        fill="#71CB80"
      />
    </svg>
  );
};

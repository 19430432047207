import React, { useState, useEffect, useRef } from "react";
import { ChargersIcon } from "../../../assets/svgIcons/chargerssvg.jsx"; // Ensure this path is correct
import { useNavigate } from "react-router-dom";
//this charger tab is used to show all teh chargers of the specific station in the side navigation bar 
const ChargersTab = ({ setActiveLink, activeLink }) => {
  const [isChargersExpanded, setIsChargersExpanded] = useState(false);
  const chargersRef = useRef(null); // Ref for the chargers container
  const navigate = useNavigate();
  const chargers = [
    {
      name: "Naveed’s Charger",
      href: "/dashboard/chargers/a",
      //   expanded: false,
      //   details: ["Detail 1", "Detail 2"], // Example details for a charger
    },
    {
      name: "Naveed’s Green Wire",
      href: "/dashboard/chargers/b",
      //   expanded: false,
      //   details: ["Detail 3", "Detail 4"],
    },
    // Add more chargers as necessary
  ];

  // Toggle the expanded state of a charger
  // const toggleChargerExpansion = (index) => {
  //   setChargers((chargers) =>
  //     chargers.map((charger, i) => ({
  //       ...charger,
  //       expanded: i === index ? !charger.expanded : charger.expanded,
  //     }))
  //   );
  // };

  // Handle click outside to collapse the charger list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chargersRef.current && !chargersRef.current.contains(event.target)) {
        setIsChargersExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
    setActiveLink(path);
    setIsChargersExpanded(false);
  };
  const handleToggle = () => {
    setActiveLink("/dashboard/chargers"); // Set active link
    navigate("/dashboard/chargers"); // Navigate to chargers page
    setIsChargersExpanded(!isChargersExpanded); // Toggle the expanded state
  };

  return (
    <div ref={chargersRef}>
      <button
        className={`flex ml-[20px] items-center px-4 py-2 mt-5 rounded-md bg-[#34B44A33] ${
          activeLink.startsWith("/dashboard/chargers")
            ? "bg-green-500"
            : "text-gray-400"
        }`}
        onClick={handleToggle}
      >
        <span className="text-lg mr-3 ">
          <ChargersIcon />
        </span>
        Chargers
        <span className="ml-[95px]">
          {isChargersExpanded ? (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 15l7-7 7 7"
              />
            </svg>
          ) : (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          )}
        </span>
      </button>
      {isChargersExpanded && (
        <div className="ml-[20px] mr-[20px] mt-2">
          {chargers.map((Charger) => (
            // <React.Fragment key={index}>
            <div
              key={Charger.href}
              className="flex justify-between items-center bg-gray-700 px-4 py-2 rounded-md mt-2 cursor-pointer"
              onClick={() => handleNavigate(Charger.href)}
            >
              {Charger.name.length > 15
                ? `${Charger.name.substring(0, 15)}...`
                : Charger.name}
            </div>
            // </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChargersTab;

import React from 'react';

export const GreenArrowSvg = () => {
	return (
		<svg
			width="7"
			height="13"
			viewBox="0 0 7 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.5 1.5L6.03907 6.11589C6.27895 6.31579 6.27894 6.68421 6.03907 6.88411L0.5 11.5"
				stroke="#34B44A"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

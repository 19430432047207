import React from 'react';

export const SearchbarIcon = ({ className }) => (
	<svg
		className={className}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.11628 7.62791C1.11628 11.2242 4.03163 14.1395 7.62791 14.1395C11.2242 14.1395 14.1395 11.2242 14.1395 7.62791C14.1395 4.03163 11.2242 1.11628 7.62791 1.11628C4.03163 1.11628 1.11628 4.03163 1.11628 7.62791ZM7.62791 15.2558C3.41513 15.2558 0 11.8407 0 7.62791C0 3.41513 3.41513 0 7.62791 0C11.8407 0 15.2558 3.41513 15.2558 7.62791C15.2558 9.53341 14.5571 11.2757 13.4019 12.6126L15.8365 15.0472C16.0545 15.2652 16.0545 15.6186 15.8365 15.8365C15.6186 16.0545 15.2652 16.0545 15.0472 15.8365L12.6126 13.4019C11.2757 14.5571 9.5334 15.2558 7.62791 15.2558Z"
			fill="white"
		/>
	</svg>
);

import React from 'react';

export const UserManagementIcon = ({ className }) => (
	<svg
		className={className}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.45746 5.81554C3.57552 6.9822 3.05469 8.43359 3.05469 10.003C3.05469 13.8364 6.1658 16.9475 9.99913 16.9475C13.8325 16.9475 16.9436 13.8364 16.9436 10.003C16.9436 6.1697 13.8325 3.05859 9.99913 3.05859"
			stroke="white"
			strokeWidth="1.04167"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.1377 10.0017C5.1377 12.6892 7.31131 14.8628 9.99881 14.8628C12.6863 14.8628 14.8599 12.6892 14.8599 10.0017C14.8599 7.31424 12.6863 5.14062 9.99881 5.14062"
			stroke="white"
			strokeWidth="1.04167"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10 12.7782C11.5347 12.7782 12.7778 11.5352 12.7778 10.0004C12.7778 8.46571 11.5347 7.22266 10 7.22266"
			stroke="white"
			strokeWidth="1.04167"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

// dashboardsvg.jsx
import React from 'react';

export const BookingsIcon = ({ className }) => (
	<svg
		className={className}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.66602 1.67188V4.17187"
			stroke="white"
			strokeWidth="1.25"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.334 1.67188V4.17187"
			stroke="white"
			strokeWidth="1.25"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.83398 10.8281H12.5007"
			stroke="white"
			strokeWidth="1.25"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.83398 14.1719H10.0007"
			stroke="white"
			strokeWidth="1.25"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.3333 2.92188C16.1083 3.07188 17.5 4.13021 17.5 8.04687V13.1969C17.5 16.6302 16.6667 18.3469 12.5 18.3469H7.5C3.33333 18.3469 2.5 16.6302 2.5 13.1969V8.04687C2.5 4.13021 3.89167 3.08021 6.66667 2.92188H13.3333Z"
			stroke="white"
			strokeWidth="1.25"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

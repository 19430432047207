import React, { useState } from "react";
import Header from "v2/components/layout/header/header.js";
import NavigationBar from "v2/components/layout/Navigationbar/NavigationBar.jsx";
import { EmptyStateSvg } from "v2/assets/svgIcons/emptyStateSvg";
import CreateStationModal from "v2/components/specific/AddStationWidgets/CreateStationWidget";
import CreateChargerModal from "v2/components/specific/AddStationWidgets/CreateChargerWidget";
import { PlusIconSvg } from "v2/assets/svgIcons/plusIconSvg";
export default function EmptyState() {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {/* <div className="flex h-screen">
        <div className="">
          <NavigationBar />
        </div>
        <div className="flex-1 flex flex-col overflow-hidden">
          <Header
            mainText="Naveed's Stations (1621 Stations)"
            subText="Track, Manage and Forecast your stations and bookings."
          /> */}
      <div className="flex flex-col w-full h-full bg-gray-50 relative overflow-hidden overflow-y-auto justify-center items-center">
        <div className="flex flex-col justify-center items-center text-center">
          <div className="mb-4">
            <EmptyStateSvg />
          </div>
          <span className="text-black text-base mb-[8px] mt-[24px] md:text-[25px] font-semibold">
            You haven't Created your Station yet
          </span>
          <span className="text-gray-500 text-base md:text-[20px] mb-4">
            Click here to start creating your own EV Charging Station with
            VerteCharge
          </span>
          <button
            onClick={openModal}
            className="bg-custom-green text-white font-semibold py-1 px-3 md:py-2 md:px-4 rounded-lg shadow-lg hover:bg-custom-green flex items-center justify-center"
          >
            <span className="mr-2">
              <PlusIconSvg />
            </span>
            Create Station
          </button>
        </div>
        <CreateStationModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
      {/* </div>
      </div> */}
    </>
  );
}

import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import { RightArrowSvg } from "v2/assets/svgIcons/rightArrowSvg";
import { PlusIconSvg } from "v2/assets/svgIcons/plusIconSvg";
import { UpArrowSvg } from "v2/assets/svgIcons/upArrowSvg.jsx";
import "v2/assets/customCSS/greenCheckbox.css";
import { useNavigate } from "react-router-dom";

// Fix leaflet marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const LocationMarker = ({ setLocation }) => {
  const map = useMapEvents({
    click(e) {
      setLocation(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return null;
};

const Modal = ({ isOpen, onClose }) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate(); // This hook gives you access to the history instance that you may use to navigate.

  const [location, setLocation] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [chargerData, setChargerData] = useState({
    stationId: "",
    level: "",
    connector_type: "",
    power_kW: "",
    ac: false,
    voltage_V: "",
    status: "Available",
  });

  const [formData, setFormData] = useState({
    station_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    global_code: "",
    compound_code: "",
    opening_hours: {
      monday: { open_time: "08:00", close_time: "23:55" },
      tuesday: { open_time: "08:00", close_time: "23:55" },
      wednesday: { open_time: "08:00", close_time: "23:55" },
      thursday: { open_time: "08:00", close_time: "23:55" },
      friday: { open_time: "08:00", close_time: "23:55" },
      saturday: { open_time: "08:00", close_time: "23:55" },
      sunday: { open_time: "08:00", close_time: "23:55" },
    },
    phone_number: "+1122334455", // example value, adjust as needed
    all_day: true, // example value, adjust as needed
    business_status: "Available", // example value, adjust as needed
    is_affiliated: false, // example value, adjust as needed
    verified: false, // example value, adjust as needed
    district: "",
    mechanic: false,
    tyre_shop: false,
    convenience_store: false,
    cafe: false,
    atm: false,
    toilets: false,
    car_wash: false,
    parking: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleChargerInputChange = (e) => {
    const { name, value } = e.target;
    setChargerData((prev) => ({ ...prev, [name]: value }));
  };

  const navigateToCongrats = () => {
    onClose(); // Close the modal
    navigate("/congratulations"); // Navigate to the congratulations screen
  };

  const handleSubmit = () => {
    handleChargerInputChange();
    navigateToCongrats();
  };
  const handleOpeningHoursChange = (day, field, value) => {
    setFormData({
      ...formData,
      opening_hours: {
        ...formData.opening_hours,
        [day]: { ...formData.opening_hours[day], [field]: value },
      },
    });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(file); // Store the file object to be uploaded after station creation
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  const submitCharger = async () => {
    const {
      powerCapacity,
      chargerType,
      connectorType,
      level,
      voltage,
      stationId,
    } = chargerData;
    const chargerPayload = {
      station_id: stationId, // This should be set when the station is created
      level: parseInt(level),
      connector_type: connectorType,
      power_kW: parseInt(powerCapacity),
      ac: chargerType === "AC",
      voltage_V: parseInt(voltage),
      status: "Available",
    };

    try {
      const response = await fetch(
        "https://verte-charge-platforms-backend-test.vertecharge.com/charger",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: "include",
          },
          body: JSON.stringify(chargerPayload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log("Charger added successfully");
      navigateToCongrats(); // Navigate after successful API call
    } catch (error) {
      console.error("Failed to submit charger:", error);
    }
  };

  const handleNext = async () => {
    const payload = {
      ...formData,
      lat: location ? location.lat : "",
      long: location ? location.lng : "",
    };
    try {
      const response = await fetch(
        "https://verte-charge-platforms-backend-test.vertecharge.com/station",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (response.ok) {
        const jsonResponse = await response.json();
        console.log("Station creation successful:", jsonResponse);
        const stationId = jsonResponse.station_id; // Assuming station_id is returned
        if (!stationId) {
          throw new Error("Station ID is undefined");
        }
        const amenitiesPayload = {
          station_id: stationId,
          mechanic: formData.mechanic,
          tyre_shop: formData.tyre_shop,
          convenience_store: formData.convenience_store,
          cafe: formData.cafe,
          atm: formData.atm,
          toilets: formData.toilets,
          car_wash: formData.car_wash,
          parking: formData.parking,
        };

        await fetch(
          "https://verte-charge-platforms-backend-test.vertecharge.com/station/amenities",
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(amenitiesPayload),
          }
        );

        setChargerData((prev) => ({
          ...prev,
          stationId: stationId,
        }));
        setPage(2);
        if (selectedImage) {
          await uploadImage(stationId, selectedImage);
          setSelectedImage(null); // Reset or clear the selected image after upload
        }
        setPage(2);
      } else {
        const errorResponse = await response.text();
        console.error("API Error:", errorResponse);
        alert("Failed to submit the form: " + errorResponse);
      }
    } catch (error) {
      console.error("Network error:", error);
      alert("A network error occurred: " + error.message);
    }
  };
  const uploadImage = async (stationId, imageFile) => {
    const formData = new FormData();
    formData.append("station_image", imageFile);
    try {
      const imageResponse = await fetch(
        `https://verte-charge-platforms-backend-test.vertecharge.com/station/image/${stationId}`,
        {
          method: "PUT", // Changed from POST to PUT
          credentials: "include",
          body: formData,
        }
      );
      if (imageResponse.ok) {
        const imageJsonResponse = await imageResponse.json();
        console.log("Image upload successful:", imageJsonResponse);
      } else {
        console.error("Failed to upload image:", await imageResponse.text());
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  if (!isOpen) return null;

  const renderStationForm = () => (
    <>
      <h2 className="text-2xl font-Inter font-semibold text-black font-inter mb-6">
        Create Your Station
      </h2>
      <div className="space-y-6">
        <div className="flex items-center space-x-4">
          <div className="relative w-24 h-24 flex justify-center items-center">
            {selectedImage ? (
              <div className="relative">
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="w-full h-full object-cover rounded-full border-2 border-gray-400"
                />
                <button
                  onClick={handleImageRemove}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                >
                  &times;
                </button>
              </div>
            ) : (
              <div className="border-2 border-dashed border-gray-400 rounded-full w-full h-full flex justify-center items-center cursor-pointer">
                <input
                  type="file"
                  className="hidden"
                  onChange={handleImageUpload}
                  id="upload-input"
                />
                <label
                  htmlFor="upload-input"
                  className="customGreen cursor-pointer"
                >
                  <div className="flex flex-col items-center">
                    <div className="bg-custom-green text-white rounded-md w-8 h-8 flex items-center justify-center">
                      <PlusIconSvg />
                    </div>
                  </div>
                </label>
              </div>
            )}
          </div>
          <label
            htmlFor="upload-input"
            className="flex font-inter text-custom-green underline flex-row cursor-pointer"
          >
            <span>
              <UpArrowSvg />
            </span>
            Upload from Device
          </label>
        </div>
        <div>
          <label className="text-black text-[16px] font-inter font-semibold block mb-2">
            Station Details
          </label>
        </div>
        <div>
          <label className="block mb-2 text-[14px] font-inter font-semibold text-custom-green">
            Station Name
          </label>
          <input
            type="text"
            name="station_name"
            value={formData.station_name}
            onChange={handleInputChange}
            className="w-full border border-gray-300 p-3 text-[14px] font-inter rounded-md"
            placeholder="Station Name"
          />
        </div>
        <div>
          <label className="text-black text-[16px] font-inter font-semibold block mb-2">
            Station Address
          </label>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <label className="text-[14px] font-semibold font-inter text-custom-green block mb-2">
              Address
            </label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="w-full border placeholder-14px placeholder-inter border-gray-300 p-3 rounded-md"
              placeholder="Address"
            />
          </div>
          <div>
            <label className="text-custom-green text-[14px] font-inter font-semibold block mb-2">
              City
            </label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              className="w-full border border-gray-300 p-3 placeholder-14px placeholder-inter rounded-md"
              placeholder="City"
            />
          </div>
          <div>
            <label className="text-custom-green text-[14px] font-inter font-semibold block mb-2">
              State
            </label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              className="w-full border placeholder-14px placeholder-inter border-gray-300 p-3 rounded-md"
              placeholder="State"
            />
          </div>
          <div>
            <label className="text-custom-green text-[14px] font-inter font-semibold block mb-2">
              Country
            </label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              className="w-full border border-gray-300 p-3 rounded-md"
              placeholder="Country"
            />
          </div>
          <div>
            <label className="text-custom-green text-[14px] font-inter font-semibold block mb-2">
              District
            </label>
            <input
              type="text"
              name="district"
              value={formData.district}
              onChange={handleInputChange}
              className="w-full border placeholder-14px placeholder-inter border-gray-300 p-3 rounded-md"
              placeholder="District"
            />
          </div>
          <div>
            <label className="text-custom-green text-[14px] font-inter font-semibold block mb-2">
              Longitude
            </label>
            <input
              type="text"
              value={location ? location.lng : ""}
              className="w-full border placeholder-14px placeholder-inter border-gray-300 p-3 rounded-md"
              placeholder="Longitude"
              readOnly
            />
          </div>
          <div>
            <label className="text-custom-green text-[14px] font-inter font-semibold block mb-2">
              Latitude
            </label>
            <input
              type="text"
              value={location ? location.lat : ""}
              className="w-full border placeholder-14px placeholder-inter border-gray-300 p-3 rounded-md"
              placeholder="Latitude"
              readOnly
            />
          </div>
          <div>
            <label className="text-custom-green text-[14px] font-inter font-semibold block mb-2">
              Global Code
            </label>
            <input
              type="text"
              name="global_code"
              value={formData.global_code}
              onChange={handleInputChange}
              className="w-full border border-gray-300 placeholder-[14px] placeholder-inter p-3 rounded-md"
              placeholder="Global Code"
            />
          </div>
          <div>
            <label className="text-custom-green text-[14px] font-inter font-semibold block mb-2">
              Compound Code
            </label>
            <input
              type="text"
              name="compound_code"
              value={formData.compound_code}
              onChange={handleInputChange}
              className="w-full border border-gray-300 p-3 placeholder-14px placeholder-inter rounded-md"
              placeholder="Compound Code"
            />
          </div>
        </div>
        <div className="my-6">
          <label className="block mb-2 text-black text-[16px] font-inter font-semibold">
            Select Location on Map
          </label>
          <MapContainer
            center={[51.505, -0.09]}
            zoom={13}
            style={{ height: "300px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {location && <Marker position={location} />}
            <LocationMarker setLocation={setLocation} />
          </MapContainer>
        </div>
        <div className="mt-4">
          <label className="block mb-2 text-black text-[16px] font-inter font-semibold">
            Station Availability
          </label>
          <div className="grid grid-cols-3 gap-4">
            <div className="font-semibold"></div>
            <div className=" text-black text-[12px] font-inter font-semibold">
              Start Time
            </div>
            <div className=" text-black text-[12px] font-inter font-semibold">
              End Time
            </div>
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((day) => (
              <React.Fragment key={day}>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    defaultChecked
                    className="custom-checkbox"
                  />
                  <span>{day.charAt(0).toUpperCase() + day.slice(1)}</span>
                </div>
                <div>
                  <input
                    type="time"
                    value={formData.opening_hours[day].open_time}
                    onChange={(e) =>
                      handleOpeningHoursChange(day, "open_time", e.target.value)
                    }
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                </div>
                <div>
                  <input
                    type="time"
                    value={formData.opening_hours[day].close_time}
                    onChange={(e) =>
                      handleOpeningHoursChange(
                        day,
                        "close_time",
                        e.target.value
                      )
                    }
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <label className="block mb-4 text-black text-[16px] font-inter font-semibold">
            Station Facilities
          </label>
          <div className="flex flex-wrap items-center justify-between gap-4">
            {[
              { label: "Mechanic", name: "mechanic" },
              { label: "Tyre Shop", name: "tyre_shop" },
              { label: "Convenience Store", name: "convenience_store" },
              { label: "Cafe", name: "cafe" },
              { label: "ATM Machine", name: "atm" },
              { label: "Toilets", name: "toilets" },
              { label: "Car Wash", name: "car_wash" },
              { label: "Parking", name: "parking" },
            ].map((facility) => (
              <div key={facility.name} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name={facility.name}
                  checked={formData[facility.name]}
                  onChange={handleInputChange}
                  className="custom-checkbox"
                />
                <span>{facility.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-end mt-6">
          <button
            type="button"
            onClick={() => setPage(1)}
            className="w-10 h-10 px-2 py-2.5 bg-gray-200 border border-gray-300 justify-center rounded-l-md items-center gap-2 inline-flex"
          >
            <div className="text-neutral-950 text-sm font-medium font-['Inter'] leading-tight">
              1
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleNext()}
            className="w-10 h-10 px-2 py-2.5 bg-white border border-gray-300 justify-center items-center gap-2 inline-flex"
          >
            <div className="text-neutral-950 text-sm font-medium font-['Inter'] leading-tight">
              2
            </div>
          </button>
          <button
            onClick={() => handleNext()}
            className="flex flex-row px-4 py-2 bg-white justify-center items-center text-inter text-gray-700 rounded-r-md border border-gray-300"
          >
            Next
            <span>
              <RightArrowSvg />
            </span>
          </button>
        </div>
      </div>
    </>
  );

  const renderChargerForm = () => (
    <>
      <h2 className="text-xl text-inter font-semibold mb-4">
        <span className="text-inter text-[20px] text-custom-green">
          "Add Charger"
        </span>{" "}
        to Your Station
      </h2>
      <h2 className="text-black font-inter text-[16px]">Charger Details</h2>
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* <div>
            <label className="block mb-1 text-[14px] text-custom-green">
              Charger Name
            </label>
            <input
              type="text"
              name="chargerName"
              value={chargerData.chargerName}
              onChange={handleChargerInputChange}
              className="w-full border border-gray-300 p-2 rounded-md"
              placeholder="Charger Name"
            />
          </div> */}
          <div>
            <label className="block mb-1 text-[14px] text-custom-green">
              Power Capacity
            </label>
            <input
              type="text"
              name="power_kW"
              value={chargerData.power_kW}
              onChange={handleChargerInputChange}
              className="w-full border border-gray-300 p-2 rounded-md"
              placeholder="Power Capacity"
            />
          </div>
          {/* <div>
            <label className="block mb-1 custom-green">Charger Type</label>
            <select
              name="chargerType"
              value={chargerData.ac}
              onChange={handleChargerInputChange}
              className="w-full border text-[14px] border-gray-300 p-2 rounded-md"
            >
              <option value="AC">AC Charger</option>
              <option value="DC">DC Charger</option>
            </select>
          </div> */}
          <div>
            <label className="block mb-1 text-[14px] custom-green">
              Connector Type
            </label>
            <select
              name="Connector Type"
              value={chargerData.connector_type}
              onChange={handleChargerInputChange}
              id="Connector Type"
              className="w-full border border-gray-300 p-2 rounded-md"
            >
              <option value="Type1">Type 1</option>
              <option value="CHAdeMO">CHAdeMO</option>
              <option value="Type 2">Type 2</option>
              <option value="CCS2">CCS2</option>
            </select>
          </div>
          <div>
            <label className="block mb-1 custom-green">Level</label>
            <input
              type="text"
              name="level"
              value={chargerData.level}
              onChange={handleChargerInputChange}
              className="w-full border border-gray-300 p-2 rounded-md"
              placeholder="Charger Level"
            />
          </div>
          <div>
            <label className="block mb-1 custom-green">Voltage</label>
            <input
              type="text"
              name="voltage_V"
              value={chargerData.voltage_V}
              onChange={handleChargerInputChange}
              className="w-full border border-gray-300 p-2 rounded-md"
              placeholder="Charger Voltage"
            />
          </div>
          {/* <div className="col-span-3 flex justify-end">
            <button className="custom-green text-white px-4 py-2 rounded-md">
              + Add More Connector
            </button>
          </div> */}
        </div>
        <div className="border-t border-gray-300 my-4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-1 custom-green">Select Station</label>
            <select className="w-full border border-gray-300 p-2 rounded-md">
              <option>Station Name</option>
            </select>
          </div>
          <div>
            <label className="block mb-1 custom-green">
              Select Pricing Model
            </label>
            <select className="w-full border border-gray-300 p-2 rounded-md">
              <option>Pricing Model</option>
            </select>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end mt-6">
        <button
          type="button"
          onClick={() => setPage(1)}
          className="w-10 h-10 px-2 py-2.5 bg-gray-200 border border-gray-300 justify-center rounded-l-md items-center gap-2 inline-flex"
        >
          <div className="text-neutral-950 text-sm font-medium font-['Inter'] leading-tight">
            1
          </div>
        </button>
        <button
          type="button"
          onClick={() => setPage(2)}
          className="w-10 h-10 px-2 py-2.5 bg-white border border-gray-300 justify-center items-center gap-2 inline-flex"
        >
          <div className="text-neutral-950 text-sm font-medium font-['Inter'] leading-tight">
            2
          </div>
        </button>
        <button
          onClick={navigateToCongrats}
          className="flex flex-row px-4 py-2 bg-white justify-center items-center text-gray-700 rounded-r-md border border-gray-300"
        >
          Skip
        </button>
        <button
          onClick={submitCharger}
          className="flex flex-row px-4 py-2 bg-custom-green justify-center items-center text-white rounded-r-md border border-gray-300"
        >
          Submit
        </button>
      </div>
    </>
  );

  return (
    <div className="fixed inset-0 m-[40px] bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg w-full max-h-full overflow-y-auto">
        <div className="relative p-8">
          <button
            className="absolute top-2 right-2 text-gray-500"
            onClick={onClose}
          >
            &times;
          </button>
          {page === 1 ? renderStationForm() : renderChargerForm()}
        </div>
      </div>
    </div>
  );
};

export default Modal;


// RevenueCard.js
import React, { useEffect, useRef } from 'react';
import 'chart.js/auto';
import ApexCharts from 'apexcharts';
import { GreenArrowSvg } from 'v2/assets/svgIcons/GreenArrowSvg';

const options = {
	chart: {
		height: 'auto',
		type: 'area',
		fontFamily: 'Inter, sans-serif',
		dropShadow: {
			enabled: false,
		},
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false, // Disable zoom if not needed
		},
		sparkline: {
			enabled: true, // Enable sparkline to remove all paddings and make it minimal
		},
	},
	tooltip: {
		enabled: true,
		x: {
			show: false,
		},
	},
	fill: {
		type: 'gradient',
		gradient: {
			opacityFrom: 0.55,
			opacityTo: 0,
			shade: '#34B44A',
			gradientToColors: ['#34B44A'],
		},
	},
	dataLabels: {
		enabled: false,
	},
	stroke: {
		width: 2,
	},
	grid: {
		show: false,
		strokeDashArray: 4,
		padding: {
			left: 2,
			right: 2,
			top: 0,
		},
	},
	series: [
		{
			data: [6500, 6418, 6456, 6526, 6356, 6456],
			color: '#34B44A',
		},
	],
	xaxis: {
		categories: [
			'01 March',
			'02 March',
			'03 March',
			'04 March',
			'05 March',
			'06 March',
			'07 March',
		],
		labels: {
			show: false,
		},
		axisBorder: {
			show: false,
		},
		axisTicks: {
			show: false,
		},
	},
	yaxis: {
		show: false,
	},
};

if (
	document.getElementById('area-chart') &&
	typeof ApexCharts !== 'undefined'
) {
	const chart = new ApexCharts(document.getElementById('area-chart'), options);
	chart.render();
}

const RevenueCard = () => {
	const chartRef = useRef(null); // Create a ref for the chart container

	useEffect(() => {
		if (chartRef.current) {
			// Make sure the ref is linked to an element
			const chart = new ApexCharts(chartRef.current, options);
			chart.render();
			return () => {
				// Cleanup function to destroy the chart when the component unmounts
				chart.destroy();
			};
		}
	}, []); // Empty dependency array means this effect will only run once on mount
	return (
		<div className="w-[214px] h-[325px] p-6 mr-[24px] bg-white rounded-lg shadow-md max-w-sm">
			<div className="text-[16px] font-inter font-semibold text-gray-700">
				Revenue Generation for{' '}
				<span className=" text-green-500">March 2023</span>
			</div>
			<div className="text-[32px] text-bol text-black-100">$92.5K</div>
			<div ref={chartRef} class="w-[163px] h-[50px] bg-white"></div>
			<div className=" text-green-500 text-[14px] mt-1">
				↑ 40%
				<span className="text-black text-[14px] ml-1"> vs Last Month</span>
			</div>
			<div className="text-gray-600 text-[12px]"> + 32.5K over February</div>
			<div className="flex justify-center items-center">
				<button className="flex flex-row mt-4">
					<div className="text-green-500 ">View Statistics</div>
					<div className="p-[7px]">
						<GreenArrowSvg />
					</div>
				</button>
			</div>
		</div>
	);
};

export default RevenueCard;

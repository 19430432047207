import React from "react";

const StationCard = ({ stationImage, stationName, rating, address }) => {
  // Calculate the number of full, half, and empty stars
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <div className="w-[214px] h-[299px] rounded-[16px] overflow-hidden p-4 shadow-lg">
      <img
        className="w-[182px] h-[182px] rounded-[8px]"
        src={stationImage}
        alt={stationName}
      />
      <div className="py-4">
        <div className="font-semibold font-inter text-[14px] mb-2">
          {stationName}
        </div>
        <div className="font-inter text-[12px] mb-2">{address}</div>{" "}
        {/* Display the station address */}
        <div className="text-gray-700 text-base">
          <div className="flex items-center">
            <span className="mr-2 text-gray-600">{rating.toFixed(1)}</span>
            {Array(fullStars)
              .fill()
              .map((_, i) => (
                <span key={i} className="text-yellow-500">
                  ★
                </span>
              ))}
            {halfStars === 1 && <span className="text-yellow-500">☆</span>}
            {Array(emptyStars)
              .fill()
              .map((_, i) => (
                <span key={i} className="text-gray-300">
                  ★
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationCard;

import React from "react";

export const RightArrowSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.16602 6.99996H12.8327M12.8327 6.99996L6.99935 1.16663M12.8327 6.99996L6.99935 12.8333"
        stroke="#333333"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

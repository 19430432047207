import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginImg from "v2/assets/images/login.png";
import verteChargeLogo from "v2/assets/images/verteChargeLogo.png";
import googleLogo from "v2/assets/images/googleLogo.jpg";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

export default function Login() {
  //state for getting input field variables
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const toggle = () => {
    setOpen(!open);
  };
  async function loginn(e) {
    e.preventDefault();
    setErrorMessage(""); // Reset error message
    let item = { email, password };
    console.warn(item);
    try {
      let response = await fetch(
        "https://verte-charge-users-backend-test.vertecharge.com/user/login?platform=web", //url for login api
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      let result = await response.json();
      console.warn("result", result);
      // Handle the result here (e.g., navigate to a different page, show a message, etc.)
      // Navigate to another page on successful login

      if (response.ok) {
        console.log("Navigating to dashboard");
        navigate("/dashboard"); //navigating to home screen
      } else {
        throw new Error(result.message || "Login failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("Incorrect phone number or password");
      // Handle errors if the fetch request fails
    }
  }
  return (
    <div className="h-screen flex flex-row overflow-hidden">
      <div className="flex flex-col w-full lg:w-1/2 bg-white items-center justify-center">
        <div className=" flex flex-col bg-white">
          <div className="flex flex-col bg-white ">
            <div className="flex flex-row  ml-[100px] mt-[20px]">
              <img
                className="w-[240px] h-20 mt-[16px] mb-[16px]"
                src={verteChargeLogo}
                alt="Logo"
              />
            </div>
            <form action="">
              <div className="text-black font-bold text-[36px] font-inter mb-[7px]">
                Log In
              </div>
              <div className="text-gray-500 text-[16px] font-inter mb-[7px]">
                Welcome back! Please enter your details.
              </div>
              <div className="text-black text-[14px] font-inter font-semibold mb-[7px]">
                Email
              </div>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-black border border-zinc-300 mb-[12px] w-[360px] h-[40px] px-[29px] py-[15px] bg-white rounded-[10px]  justify-center items-center inline-flex grow shrink basis-0 text-sm font-inter"
                placeholder="Enter Your email"
                required
              />
              {errorMessage && (
                <div>
                  <p className="text-red-500 text-xs">{errorMessage}</p>
                </div>
              )}
              <div class="mb-[7px] text-black font-semibold text-[14px] font-inter">
                Password
              </div>
              <div className="mb-[12px] w-[360px] h-[40px] bg-white rounded-[10px] border border-zinc-300 justify-center items-center inline-flex grow shrink basis-0 ">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={open === false ? "password" : "text"}
                  className=" text-black w-[360px] h-[40px] px-[29px] py-[12px] border border-zinc-300 rounded-[10px] text-[14px] font-inter"
                  placeholder="XXXXXXXXXXXX"
                  required
                />
                {open === false ? (
                  <AiFillEye className="m-4" onClick={toggle} />
                ) : (
                  <AiFillEyeInvisible className="m-4" onClick={toggle} />
                )}
              </div>
              {errorMessage && (
                <div>
                  <p className="text-red-500 text-xs">{errorMessage}</p>
                </div>
              )}
              <div class="flex items-center justify-between">
                <label class="flex items-center space-between">
                  <input type="checkbox" class="form-checkbox text-green-500" />
                  <span class="text-gray-700">Remember me</span>
                </label>
                <a href="#" class="text-green-500 hover:underline">
                  Forgot password?
                </a>
              </div>
              <button
                type="submit"
                onClick={loginn}
                className="  w-[360px] h-[40px] px-[29px] py-[15px] bg-custom-green rounded-[10px] border border-zinc-300 justify-center items-center inline-flex text-center text-white text-[16px] font-semibold font-inter"
              >
                Log In
              </button>
            </form>
            <button className=" w-[360px] flex-row h-[45px] mt-[20px] px-[29px] py-[12px] rounded-[10px] border border-zinc-300 justify-center items-center inline-flex text-center text-black text-lg font-semibold font-inter">
              <img src={googleLogo} className="w-6 h-6 " alt="Google Logo" />
              <div className="text-semibold font-inter font-[16px] pl-[12px]">
                Sign in with Google
              </div>
            </button>
            <div className="flex flex-row mt-[16px]">
              <span className="text-neutral-900 text-[14px] font-inter">
                Don't have an account?
              </span>
              <span className="text-custom-green text-[14px] font-inter">
                <a href="/signup" className="button">
                  Sign Up
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <img
        src={LoginImg}
        className="hidden lg:block w-1/2 h-full object-cover"
        alt="Side Img"
      />
    </div>
  );
}

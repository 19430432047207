import React, { useEffect, useState } from "react";

const Customers = ({ percentage }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // This timeout starts the animation shortly after the component mounts
    const timeout = setTimeout(() => setLoaded(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  // Decrease height with h-8 for the container and filler
  // Adjust width to 3/4 by w-3/4 for a smaller width or change as needed
  const containerStyle = "bg-green-200 rounded-md"; // Container with decreased height and rounded corners
  const fillerStyle =
    "bg-green-500 transition-all duration-1000 ease-in-out rounded-md"; // Filler with decreased height and rounded corners

  return (
    <div className="w-[404px]">
      {" "}
      {/* Adjust the width as needed */}
      <div className="text-lg font-semibold mb-2">Customers</div>
      <div
        className={`h-8 overflow-hidden ${containerStyle}`} // Decreased height with h-8
      >
        <div
          className={`${fillerStyle} h-full flex items-center justify-center text-white text-sm transition-opacity duration-1000 ${
            loaded ? "opacity-100" : "opacity-0"
          }`}
          style={{ width: loaded ? `${percentage}%` : "0%" }}
        >
          {loaded ? `${percentage}%` : ""}
        </div>
      </div>
      <div className="flex flex-col justify-start mt-4">
        <div className="flex mr-4">
          <span className="inline-block w-3 h-3 bg-green-500 rounded-full mr-2"></span>
          <span className="text-sm">
            VerteCharge Loyal Customers Since July
          </span>
        </div>
        <div className="flex ">
          <span className="inline-block w-3 h-3 bg-green-200 rounded-full mr-2"></span>
          <span className="text-sm">New Customers</span>
        </div>
      </div>
      <div class="flex mt-[16px]">
        <div class=" rounded-full h-[32px] px-2 py-2 w-[32px] -mr-2 text-xs text-gray-700 bg-orange-200">
          SJ
        </div>
        <div class=" rounded-full h-[32px] px-2 py-2 w-[32px] -mr-2 text-xs text-gray-700 bg-pink-200">
          JK
        </div>
        <div class=" rounded-full h-[32px] px-2 py-2 w-[32px] -mr-2 text-xs text-gray-700 bg-yellow-200">
          DF
        </div>
        <div class=" rounded-full h-[32px] px-2 py-2 w-[32px] -mr-2 text-xs text-gray-700 bg-green-200">
          SR
        </div>
        <div class=" rounded-full h-[32px] px-2 py-2 w-[32px] -mr-2 text-xs text-gray-700 bg-pink-200">
          YR
        </div>
        <div class=" rounded-full h-[32px] px-2 py-2 w-[32px] -mr-2 text-xs text-gray-700 bg-red-200">
          CM
        </div>
        <div class=" rounded-full h-[32px] px-2 py-2 w-[32px] -mr-2 text-xs text-gray-700 bg-purple-200">
          NS
        </div>
        <span class="rounded-full h-[32px] py-2 px-1 w-[32px] -mr-2 text-xs text-gray-700 bg-orange-200">
          +999
        </span>
      </div>
    </div>
  );
};

export default Customers;

import React from 'react';

export const StationsIcon = ({ className }) => (
	<svg
		className={className}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.91602 18.3346V4.16797C2.91602 2.5013 4.03268 1.66797 5.41602 1.66797H12.0827C13.466 1.66797 14.5827 2.5013 14.5827 4.16797V18.3346H2.91602Z"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1.66602 18.3242H15.8327"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.99102 8.33125H10.516C11.3827 8.33125 12.091 7.91458 12.091 6.75625V5.73125C12.091 4.57292 11.3827 4.15625 10.516 4.15625H6.99102C6.12435 4.15625 5.41602 4.57292 5.41602 5.73125V6.75625C5.41602 7.91458 6.12435 8.33125 6.99102 8.33125Z"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.41602 10.8242H7.91602"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.584 13.3378L18.334 13.3294V8.32943L16.6673 7.49609"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

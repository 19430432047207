import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const ConnectorTypesCard = ({ChargerTypeData}) => {
    if (!Array.isArray(ChargerTypeData) || ChargerTypeData.some(isNaN)) {
        console.error('Invalid ChargerTypeData: Expected an array of numbers');
        return null; // Optionally render an error or a fallback component
      }
      console.log(ChargerTypeData)
  const data = {
    labels: ['Type - 1', 'Type - 2', 'CCS', 'CHAdeMO', 'GB/T'],
    datasets: [
      {
        data: ChargerTypeData, // Example data
        backgroundColor: ['#4caf50', '#81c784', '#c8e6c9', '#000000', '#b0bec5'],
        borderColor: ['#fff'], // White borders
        borderWidth: 0.5,
        width: 2, // Add this for thicker segments
        borderRadius: 10, // Add this for rounded segments
        spacing: 2, // Optional: adds spacing between segments
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: '80%', // Adjust cutout percentage for thicker doughnut
    plugins: {
      legend: {
        display: false, // Hide the legend as your design doesn't show one
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
  };

  return (
    <div className="p-2 bg-white max-w-full w-full max-h-[278px] rounded-lg shadow-md">
      <div className="flex flex-col items-center">
        <div className="relative p-2 w-full max-h-[180px] h-60"> {/* Set width and height as needed */}
          <Doughnut data={data} options={options} />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <div className="text-sm text-gray-500">Connector Types</div>
            <div className="text-xl font-semibold">132</div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center space-x-2">
          <span className="flex items-center">
            <span className="h-3 w-3 bg-green-500 mr-2 rounded-full"></span> Type - 1
          </span>
          <span className="flex items-center">
            <span className="h-3 w-3 bg-green-400 mr-2 rounded-full"></span> Type - 2
          </span>
          <span className="flex items-center">
            <span className="h-3 w-3 bg-gray-300 mr-2 rounded-full"></span> CCS
          </span>
          <span className="flex items-center">
            <span className="h-3 w-3 bg-green-300 mr-2 rounded-full"></span> CHAdeMO
          </span>
          <span className="flex items-center">
            <span className="h-3 w-3 bg-gray-400 mr-2 rounded-full"></span> GB/T
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConnectorTypesCard;

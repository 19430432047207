// dashboardsvg.jsx
import React from 'react';

export const MonetizationIcon = ({ className }) => (
	<svg
		className={className}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.91797 11.4536C7.91797 12.262 8.54298 12.912 9.30965 12.912H10.8763C11.543 12.912 12.0846 12.3453 12.0846 11.637C12.0846 10.8786 11.7513 10.6036 11.2596 10.4286L8.7513 9.55364C8.25964 9.37864 7.92631 9.11198 7.92631 8.34531C7.92631 7.64531 8.46796 7.07031 9.13463 7.07031H10.7013C11.468 7.07031 12.093 7.72031 12.093 8.52865"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.002 6.25V13.75"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.3337 10.0052C18.3337 14.6052 14.6003 18.3385 10.0003 18.3385C5.40032 18.3385 1.66699 14.6052 1.66699 10.0052C1.66699 5.40521 5.40032 1.67188 10.0003 1.67188"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.3343 5.00521V1.67188H15.001"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.167 5.83854L18.3337 1.67188"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

import React, { useState, useEffect, createRef } from "react";
import { useNavigate } from "react-router-dom";
import EVcharger from "../features/images/EVcharger.png";
import verteChargeLogo from "../features/images/verteChargeLogo.png";
import axios from "axios";
import Cookies from "js-cookie";
export default function EmailOTP() {
  const navigate = useNavigate();
  const otpLength = 4; // Assuming OTP length is 4
  const [otp, setOtp] = useState(new Array(otpLength).fill(""));
  const [otpError, setOtpError] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState("");
  const otpInputRefs = Array(otpLength)
    .fill()
    .map(() => createRef());
  // Debugging line to check the token
  useEffect(() => {
    async function sendOtp() {
      const token = Cookies.get("userToken");
      try {
        const response = await fetch(
          "https://verte-charge-users-backend-test.vertecharge.com/otp",
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("OTP sent successfully", response.data);
      } catch (error) {
        console.error("Error sending OTP:", error.message);
      }
    }
    sendOtp();
  }, []);
  const handleChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value) || value === "") {
      setOtp([...otp.slice(0, index), value, ...otp.slice(index + 1)]);
      // Focus next input
      if (value !== "" && index < otpLength - 1) {
        otpInputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      otpInputRefs[index - 1].current.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");
    setOtpError(false);

    // Your OTP verification logic here

    if (otpValue.length === otpLength) {
      try {
        const response = await fetch(
          `https://verte-charge-users-backend-test.vertecharge.com/otp?otp=${otpValue}`,
          {
            method: "PUT",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        console.log(data); // Assuming the response is JSON
        if (response.ok) {
          // Handle successful OTP verification
          setVerificationMessage("OTP verified successfully.");
          navigate("/login"); // Navigate to the next page or show success message
        } else {
          // Handle failed OTP verification
          setOtpError(true);
          setVerificationMessage(data.message || "OTP verification failed.");
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        setOtpError(true);
        setVerificationMessage("An error occurred while verifying OTP.");
      }
    } else {
      setOtpError(true);
      setVerificationMessage("Please enter a valid OTP.");
    }
  };
  return (
    <div className="h-screen flex flex-row lg:overflow-hidden">
      <img
        src={EVcharger}
        className="hidden lg:block w-1/2 h-full object-cover"
        alt="Side Img"
      />
      <div className="flex flex-col bg-white ml-[100px]">
        <div className="flex flex-row  ml-[100px] mt-[20px]">
          <img
            className="w-[240px] h-20 mt-[16px] mb-[24px]"
            src={verteChargeLogo}
            alt="Logo"
          />
        </div>
        <div className="text-center text-slate-800 text-[46px] ml-[50px] font-bold font-['Roboto']">
          OTP Verification
        </div>
        <div className="text-center text-slate-800 text-lg ml-[50px] font-normal font-['Roboto'] my-[20px]">
          Enter Your Verification Code
        </div>
        <div className="text-center text-zinc-500 ml-[50px] text-md font-normal font-['Roboto']">
          OTP has been sent to your registered
          <br />
          email address
        </div>
        <form
          onSubmit={handleSubmit}
          className=" ml-[50px] flex flex-col items-center"
        >
          <div className="flex space-x-2">
            {otp.map((data, index) => (
              <input
                className="w-[60px] h-[60px] rounded-[5px] border border-zinc-500 text-center"
                key={index}
                type="text"
                name={`otp-${index}`}
                maxLength="1"
                value={data}
                ref={otpInputRefs[index]}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                autoFocus={index === 0}
              />
            ))}
          </div>
          {otpError && (
            <div className="error text-red-500">{verificationMessage}</div>
          )}
          {!otpError && verificationMessage && (
            <div className="text-green-500">{verificationMessage}</div>
          )}
          <div>
            <span className=" ml-[25px] text-neutral-900 text-md text-center items-center font-normal font-['Roboto']">
              Didn’t receive a code.{" "}
            </span>
            <span className="text-green-500 text-md font-semibold font-['Roboto'] underline">
              Resend
            </span>
          </div>
          <button
            type="submit"
            className="w-[314px] h-[53px] ml-[30px] my-[20px] px-[29px] py-[15px] bg-green-500 rounded-[10px] border border-green-500 justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-white text-base font-semibold font-['Roboto']">
              Verify
            </div>
          </button>
        </form>
      </div>
    </div>
  );
}
import React from "react";

export const CarWashIconSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7059 9.1699C16.7059 9.43791 16.4826 9.66125 16.2146 9.66125H4.47573C4.20772 9.66125 3.98438 9.43791 3.98438 9.1699C3.98438 8.90189 4.20772 8.67855 4.47573 8.67855H5.13682L5.38696 7.4993C5.61924 6.35579 6.11059 5.31055 8.05814 5.31055H12.6411C14.5887 5.31055 15.0711 6.36472 15.3123 7.4993L15.5624 8.67855H16.2235C16.4826 8.67855 16.7059 8.90189 16.7059 9.1699Z"
        fill="#71CB80"
      />
      <path
        d="M16.983 12.8593C16.8848 11.7873 16.5989 10.6349 14.5084 10.6349H6.18222C4.09173 10.6349 3.80585 11.7873 3.70758 12.8593L3.3413 16.8348C3.29663 17.3262 3.45744 17.8265 3.79692 18.2017C4.14533 18.5769 4.62775 18.7913 5.15484 18.7913H6.37876C7.43293 18.7913 7.63841 18.1838 7.77241 17.7907L7.90642 17.3977C8.05829 16.951 8.09403 16.8348 8.68365 16.8348H12.0248C12.6145 16.8348 12.6323 16.8974 12.8021 17.3977L12.9361 17.7907C13.0701 18.1928 13.2666 18.7913 14.3297 18.7913H15.5537C16.0718 18.7913 16.5632 18.5769 16.9116 18.2017C17.2511 17.8265 17.4119 17.3351 17.3672 16.8348L16.983 12.8593ZM8.38884 14.2173H6.43236C6.16435 14.2173 5.94101 13.9939 5.94101 13.7259C5.94101 13.4579 6.16435 13.2346 6.43236 13.2346H8.38884C8.65685 13.2346 8.88019 13.4579 8.88019 13.7259C8.88019 13.9939 8.65685 14.2173 8.38884 14.2173ZM14.2583 14.2173H12.3018C12.0338 14.2173 11.8104 13.9939 11.8104 13.7259C11.8104 13.4579 12.0338 13.2346 12.3018 13.2346H14.2583C14.5263 13.2346 14.7496 13.4579 14.7496 13.7259C14.7496 13.9939 14.5263 14.2173 14.2583 14.2173Z"
        fill="#71CB80"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1275 0.0448864C10.0544 -0.0149621 9.94693 -0.0149621 9.87379 0.0448864C9.87379 0.0448864 9.32496 0.494318 8.88595 1.1142C8.58144 1.54432 8.33398 2.0553 8.33398 2.55682C8.33398 3.44527 9.08085 4.16667 10.0007 4.16667C10.9205 4.16667 11.6673 3.44527 11.6673 2.55682C11.6673 2.0553 11.4199 1.54432 11.1154 1.1142C10.6763 0.494318 10.1275 0.0448864 10.1275 0.0448864Z"
        fill="#71CB80"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.9615 0.0447338C5.88836 -0.0151147 5.78091 -0.0151147 5.70777 0.0447338C5.70777 0.0447338 5.15895 0.494166 4.71993 1.11405C4.41542 1.54417 4.16797 2.05515 4.16797 2.55667C4.16797 3.44511 4.91483 4.16651 5.83464 4.16651C6.75444 4.16651 7.5013 3.44511 7.5013 2.55667C7.5013 2.05515 7.25385 1.54417 6.94934 1.11405C6.51032 0.494166 5.9615 0.0447338 5.9615 0.0447338Z"
        fill="#71CB80"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2935 0.0448864C14.2204 -0.0149621 14.1129 -0.0149621 14.0398 0.0448864C14.0398 0.0448864 13.491 0.494318 13.052 1.1142C12.7475 1.54432 12.5 2.0553 12.5 2.55682C12.5 3.44527 13.2469 4.16667 14.1667 4.16667C15.0865 4.16667 15.8333 3.44527 15.8333 2.55682C15.8333 2.0553 15.5859 1.54432 15.2814 1.1142C14.8424 0.494318 14.2935 0.0448864 14.2935 0.0448864Z"
        fill="#71CB80"
      />
    </svg>
  );
};

import React, { useState } from "react";
import AddChargerImg from "v2/assets/images/AddChargerImg.png";
import AddChargerPopup from "v2/pages/AllChargers/addChargerPopup";

const EmptyChargersScreen = () => {
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  const toggleModal = () => setShowModal(!showModal); // Function to toggle the modal

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4 bg-white">
      <div className="max-w-sm">
        <img src={AddChargerImg} alt="Electric Car Charging" />
        <h2 className="mt-4 text-center text-inter text-lg font-semibold">
          You haven’t Added Charger to any of Your Station yet
        </h2>
        <p className="text-center text-inter text-sm text-gray-600 my-2">
          Click here to Add Charger to Your Station
        </p>

        <AddChargerPopup showModal={showModal} toggleModal={toggleModal} />
      </div>
    </div>
  );
};

export default EmptyChargersScreen;

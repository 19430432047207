import React, { useState } from 'react';

const ConnectorStatusCard = ({ type, icon, isActive }) => {
  const [isCharging, setIsCharging] = useState(isActive);

  const handleToggleCharging = () => {
    setIsCharging(!isCharging);
  };

  return (
    <div className="flex flex-row gap-3 items-center justify-center p-2 bg-white rounded-lg shadow">
        {icon}
      <div className="font-medium">

      <span className="font-medium mr-[140px]">{type}</span>
      <button
        onClick={handleToggleCharging}
        className={`px-4 py-2 rounded text-red-500 ${isCharging ? 'bg-green-500 text-white' : 'bg-white border border-red-500'}`}
        >
        {isCharging ? 'Start Charging' : 'Stop Charging'}
      </button>
        </div>
    </div>
  );
};


export default ConnectorStatusCard;

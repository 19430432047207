import React from "react";

export const StationImageSvg = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6282_14096)">
        <rect width="48" height="48" rx="8" fill="#289997" />
        <path
          d="M21.2988 29.1562L28.1701 22.8945L35.6509 41.6796L21.2988 29.1562Z"
          fill="black"
        />
        <path
          d="M35.0977 16.8572L28.0407 23.2852L20.3577 4.00132L35.0977 16.8572Z"
          fill="white"
        />
        <path
          d="M21.7418 42.0131L11.2132 42.013L20.5227 4.27637L21.7418 42.0131Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6282_14096">
          <rect width="48" height="48" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

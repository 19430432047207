import React, { useRef, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function RevenueChart() {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "rgba(0, 128, 0, 0.6)"); // Light green at the top
      gradient.addColorStop(1, "rgba(0, 128, 0, 0.0)"); // Transparent at the bottom

      const dataset = chart.data.datasets[0];
      dataset.backgroundColor = gradient;
      chart.update();
    }
  }, []);

  const data = {
    labels: ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Revenue",
        data: [40000, 50000, 43000, 75000, 80000, 60000, 70000],
        borderColor: "#008000", // Solid green line
        pointBackgroundColor: "#008000",
        fill: true,
        tension: 0.4,
        pointRadius: 0, // Hide points initially
        pointHoverRadius: 6, // Show points on hover
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (tooltipItem) {
            return `$${tooltipItem.raw.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          // Include a dollar sign and use thousands separator
          callback: function (value, index, ticks) {
            return value.toLocaleString();
          },
        },
        grid: {
          color: "rgba(0,0,0,0.1)",
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        radius: 5, // Adjust the point radius
        hoverRadius: 8,
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
  };

  return (
    <div style={{ height: "356px" }}>
      <Line data={data} options={options} ref={chartRef} />
    </div>
  );
}

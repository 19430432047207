import React from "react";
import ReactApexChart from "react-apexcharts";
//the following station graph is being used on dashboard page to show the revenue generated for the last 3 years
class StationGraph extends React.Component {
  constructor(props) {
    super(props);
    this.chartContainerRef = React.createRef(); // Create a ref for the chart container
    this.state = {
      series: [
        {
          name: "2023",
          data: [28, 29, 33, 36, 32, 32, 33, 28, 29, 33, 36, 32],
        },
        {
          name: "2022",
          data: [12, 11, 14, 18, 17, 13, 13, 36, 32, 32, 33, 28],
        },
        {
          name: "2021",
          data: [11, 12, 11, 16, 12, 10, 19, 16, 22, 23, 36, 30],
        },
      ],
      options: {
        chart: {
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#34B44A", "#71CB80", "#A6DAB2"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Graph",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          min: 5,
          max: 40,
          labels: {
            show: false, // Hide the y-axis labels
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
      chartDimensions: {
        width: "100%",
        height: 350, // defining appropriate height based on your design.
      },
    };
  }
  updateDimensions = () => {
    const containerWidth = this.chartContainerRef.current
      ? this.chartContainerRef.current.offsetWidth
      : window.innerWidth;
    this.setState({
      chartDimensions: {
        width: containerWidth,
        height: 270, // 'auto' or could be a specific ratio of the width
      },
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    return (
      <div
        id="chart"
        ref={this.chartContainerRef}
        style={{ width: "100%", height: "100%" }}
      >
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          width={this.state.chartDimensions.width}
          height={this.state.chartDimensions.height}
        />
      </div>
    );
  }
}

export default StationGraph;

import React from "react";

export const MechanicIconSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.19588 17.3445L2.6568 17.8054C3.36068 18.5093 4.50196 18.5093 5.20585 17.8054L12.4787 10.5325C14.0294 11.0051 15.7833 10.6297 17.0096 9.40346C18.3098 8.10329 18.6514 6.21146 18.042 4.5961C18.0042 4.49592 17.8733 4.47092 17.7976 4.54666L15.6963 6.64756C15.5022 6.84163 15.1876 6.84163 14.9937 6.64756L13.3537 5.00758C13.1596 4.81351 13.1596 4.49888 13.3537 4.30499L15.4544 2.2039C15.5324 2.12594 15.5013 1.99539 15.398 1.95669C13.7843 1.35077 11.896 1.69354 10.5978 2.99167C9.37154 4.21796 8.99617 5.97183 9.46876 7.52255L2.19588 14.7954C1.492 15.4993 1.492 16.6406 2.19588 17.3445Z"
        fill="#71CB80"
      />
    </svg>
  );
};

import React from 'react';
import 'chart.js/auto';
import Chart from 'react-apexcharts';

const CurrentEnergyCard = () => {
    const options = {
        series: [80], // Gauge value
        chart: {
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: '#D6F0DB',
                    strokeWidth: '100%',
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: -15,
                        fontSize: '22px',
                        formatter: function (val) {
                            return val + ' MV';
                        },
                    },
                },
            },
        },
        fill: {
            colors: ['#71CB80'],
        },
        tooltip: {
            enabled: true,
            theme: 'light',
            y: {
                formatter: function (val) {
                    return val + '%';
                },
            },
        },
        legend: {
            show: false,
        },
    };

    return (
        <div className="p-2 bg-white rounded-lg shadow-md max-h-[278px] max-w-full w-full">
            <div className="text-lg flex flex-col items-center font-semibold text-gray-700">
                Total Power Comsuption
            </div>
            <div id="chart" className="relative max-h-[150px] flex justify-center w-full ">
                <Chart
                    options={options}
                    series={options.series}
                    type="radialBar"
                    height={260}
                    width="100%"
                    boarderRadius="100%"
                />
                

                <div className="absolute bottom-0 left-[30%] transform  text-xs">
                    0 GW
                </div>
                <div className="absolute bottom-0 right-[29%] transform text-xs">
                    2.3 GW
                </div>
            </div>
            <div className="flex justify-center text-green-500 ">
                ↑ 40% <span className="text-black">vs energy used</span>
            </div>
            
           
        </div>
    );
};

export default CurrentEnergyCard;

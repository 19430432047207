import React, { useState } from "react";
import "../../assets/customCSS/signup.css";
import { useNavigate } from "react-router-dom";
import SignupImg from "v2/assets/images/signup.png";
import verteChargeLogo from "v2/assets/images/verteChargeLogo.png";
import googleLogo from "v2/assets/images/googleLogo.jpg";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

export default function Signup() {
  //States for passing variables through textfields
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  // State for validation errors
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [rePasswordError, setRePasswordError] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  async function signUp(e) {
    e.preventDefault(); // Prevent the default form submit action
    setIsSignupSuccess(false);
    let isValid = true;
    setFirstNameError(!firstname);
    setLastNameError(!lastname);
    setEmailError(!email);
    setPasswordError(!password);
    setRePasswordError(!rePassword || password !== rePassword);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //email format
    if (!emailRegex.test(email)) {
      setEmailError(true);
      isValid = false;
    }
    if (
      !firstname ||
      !lastname ||
      !email ||
      !password ||
      !rePassword ||
      password !== rePassword
    ) {
      isValid = false;
    }
    if (!isValid) return;

    if (password !== rePassword) {
      alert("Passwords do not match!");
      return;
    }
    try {
      const response = await fetch(
        "https://verte-charge-users-backend-test.vertecharge.com/user/signup/platform", //URL for sign up API
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstname: firstname,
            lastname: lastname,
            email: email,
            password: password,
          }),
        }
      );

      if (response.status === 201) {
        setIsSignupSuccess(true);
        setTimeout(() => {
          navigate("/emailOTP");//navigating to the OTP Screen
        }, 2000); // Adjust the time as needed
      }
    } catch (error) {
      console.error("Signup Error:", error);
      if (
        error.response &&
        error.response.data.message.includes("email exists")
      ) {
        setEmailExistsError(true);
      } else {
        console.error("Error:", error.message);
      }
    }
  }

  return (
    <div className="h-screen flex flex-row overflow-hidden">
      <div className="flex flex-col w-full lg:w-1/2 bg-white ">
        <div className="flex w-[80px] h-[40px] ml-14 mt-3">
          <img className=" mb-[10px]" src={verteChargeLogo} alt="Logo" />
        </div>
        <form onSubmit={signUp} className="flex items-center justify-center">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="text-black text-[36px] font-inter font-bold">
                  Sign Up
                </div>
                <div className="text-gray-500 text-base font-normal font-inter">
                  Welcome back! Please enter your details.
                </div>
                <div className="text-black text-[12px] font-semibold font-inter mb-[5px]">
                  Name
                </div>
                <input
                  type="text"
                  value={firstname}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFirstNameError(false);
                  }}
                  className="mb-[5px] w-[350px] h-[35px] px-[20px] py-[12px] bg-white rounded-[10px] border border-zinc-300 justify-center items-center inline-flex text-black text-[12px] font-inter"
                  placeholder="Enter Your Name"
                  required
                />
                {firstNameError && (
                  <div className="text-red-500 text-xs">Name is required</div>
                )}
              </div>
            </div>
            <div className="text-black text-[12px] font-semibold font-inter mb-[5px]">
              Email
            </div>
            <input
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(false);
              }}
              className="mb-[5px] w-[350px] h-[35px] px-[20px] py-[12px] bg-white rounded-[10px] border border-zinc-300 justify-center items-center inline-flex grow shrink basis-0 text-black text-[12px] font-inter"
              placeholder="Enter Your Email"
              required
            />
            {emailError && (
              <div className="text-red-500 text-xs">
                Email Address is required
              </div>
            )}
            {emailExistsError && (
              <div className="text-red-500 text-xs">Email already exists</div>
            )}
            <div className="mb-[5px] text-black text-[12px] font-semibold font-inter">
              Password
            </div>
            <div className="space-between mb-[5px] w-[350px] h-[35px] py-[12px] bg-white rounded-[10px] border border-zinc-300 justify-center items-center inline-flex grow shrink basis-0">
              <input
                type={open === false ? "password" : "text"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                }}
                className="w-[350px] h-[35px] px-[20px] py-[12px] bg-white rounded-[10px] border border-zinc-300 justify-center items-center inline-flex grow shrink basis-0 text-black text-s font-normal font-['Roboto']"
                placeholder="XXXXXXXXXXXX"
                required
              />
              {open === false ? (
                <AiFillEye className="m-4" onClick={toggle} />
              ) : (
                <AiFillEyeInvisible className="m-4" onClick={toggle} />
              )}
            </div>
            {passwordError && (
              <div className="text-red-500 text-xs">Password is required</div>
            )}
            <div className="mb-[5px] text-black text-[14px] font-semibold font-inter">
              Confirm Password
            </div>
            <div className="space-between w-[350px] h-[35px] py-[12px] bg-white rounded-[10px] border border-zinc-300 justify-center items-center inline-flex grow shrink basis-0">
              <input
                type={open === false ? "password" : "text"}
                value={rePassword}
                onChange={(e) => {
                  setRePassword(e.target.value);
                  setRePasswordError(false);
                }}
                className="w-[350px] h-[35px] px-[20px] py-[12px] bg-white rounded-[10px] border border-zinc-300 justify-center items-center inline-flex grow shrink basis-0 text-black text-s font-inter"
                placeholder="XXXXXXXXXXXX"
                required
              />
              {open === false ? (
                <AiFillEye className="m-4" onClick={toggle} />
              ) : (
                <AiFillEyeInvisible className="m-4" onClick={toggle} />
              )}
            </div>
            {rePasswordError && (
              <div className="text-red-500 text-xs">Password is required</div>
            )}
            <div className="flex flex-col">
              <div className="text-black text-[14px] font-inter font-semibold mb-[5px]">
                UserName
              </div>
              <input
                type="text"
                value={lastname}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setLastNameError(false);
                }}
                className="mb-[5px] w-[350px] h-[35px] px-[20px] py-[12px] bg-white rounded-[10px] border border-zinc-300 justify-center items-center inline-flex grow shrink basis-0 text-black text-[14px] font-inter"
                placeholder="Enter Your Username"
                required
              />
              {lastNameError && (
                <div className="text-red-500 text-xs">Username is required</div>
              )}
            </div>
            <div className="flex items-center justify-between">
              <label className="flex items-center space-between">
                <input
                  type="checkbox"
                  className="form-checkbox text-green-500"
                />
                <span className="ml-1 text-gray-700">Remember me</span>
              </label>
              <a href="#" className="text-green-500 hover:underline">
                Forgot password?
              </a>
            </div>
            <button
              type="submit"
              className="w-[350px] h-[35px] mt-[10px] px-[20px] py-[12px] bg-green-500 rounded-[10px] border border-zinc-300 justify-center items-center inline-flex text-center text-white text-[18px] font-semibold font-Inter"
            >
              Sign Up
            </button>
            <button className="w-[350px] h-[35px] flex-row mt-[10px] px-[20px] py-[12px] rounded-[10px] border border-zinc-300 justify-center items-center inline-flex text-center text-black text-[18px] font-semibold font-inter">
              <img src={googleLogo} className="w-6 h-6" alt="Google Logo" />
              <div className="text-semibold font-inter font-[16px] pl-[12px]">
                Sign in with Google
              </div>
            </button>
            <div className="flex flex-row justify-center mt-[10px]">
              <span className="text-neutral-900 text-[14px] font-inter">
                Already have an account?
              </span>
              <button className="text-custom-green text-[14px] font-inter">
                <a href="/login" className="button">
                  Log In
                </a>
              </button>
            </div>
          </div>
          {isSignupSuccess && (
            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              <p className="text-custom-green">Sign up successful!</p>
            </div>
          )}
        </form>
      </div>
      <div className="lg:block w-1/2 h-full object-cover">
        <img src={SignupImg} className="w-auto h-auto" alt="Side Img" />
      </div>
    </div>
  );
}

import React from "react";

export const EmptyStateSvg = () => {
  return (
    <svg
      width="411"
      height="240"
      viewBox="0 0 411 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2622 200.225C25.1911 209.023 27.8737 217.438 29.6071 226.102H374.705C396.369 168.327 435.825 15.0638 340.741 33.6691C314.594 38.7852 267.693 40.5581 219.433 23.9961C131.749 -6.09513 123.079 12.9973 82.2376 78.2809C57.7993 117.344 13.3957 97.3222 24.2622 200.225Z"
        fill="#C5FFCF"
      />
      <path
        d="M381.629 76.203H338.613V226.571H381.629V76.203Z"
        fill="#34B44A"
      />
      <path
        d="M357.801 131.384H314.785L324.943 76.203H367.959L357.801 131.384Z"
        fill="white"
      />
      <path
        d="M343.675 122.232C346.907 119.404 347.234 114.492 344.406 111.26C341.578 108.028 336.666 107.7 333.434 110.528C330.202 113.356 329.874 118.268 332.702 121.5C335.53 124.732 340.443 125.06 343.675 122.232Z"
        fill="#F9F3EF"
      />
      <path
        d="M349.212 98.6857H333.661C333.312 98.6857 333.029 98.4025 333.029 98.0537C333.029 97.7048 333.312 97.4217 333.661 97.4217H349.212C349.561 97.4217 349.844 97.7048 349.844 98.0537C349.844 98.4025 349.561 98.6857 349.212 98.6857Z"
        fill="#011833"
      />
      <path
        d="M348.37 103.268H332.819C332.471 103.268 332.188 102.985 332.188 102.636C332.188 102.287 332.471 102.004 332.819 102.004H348.37C348.719 102.004 349.002 102.287 349.002 102.636C349.002 102.985 348.719 103.268 348.37 103.268Z"
        fill="#011833"
      />
      <path
        d="M357.801 131.387H314.785V226.574H357.801V131.387Z"
        fill="white"
      />
      <path
        d="M370.719 160.231C368.087 160.231 365.953 158.098 365.953 155.465V136.152C365.953 133.52 368.087 131.387 370.719 131.387C373.351 131.387 375.485 133.52 375.485 136.152V155.466C375.485 158.098 373.351 160.231 370.719 160.231Z"
        fill="#051423"
      />
      <path
        d="M343.774 153.083H337.077L339.557 139.019L326.246 158.204H332.944L330.463 172.269L343.774 153.083Z"
        fill="#34B44A"
      />
      <path
        d="M333.583 116.373C333.583 112.559 336.33 109.388 339.954 108.726C339.498 108.643 339.029 108.598 338.549 108.598C334.255 108.598 330.773 112.078 330.773 116.373C330.773 120.667 334.254 124.149 338.549 124.149C339.029 124.149 339.498 124.103 339.954 124.02C336.33 123.359 333.583 120.188 333.583 116.373Z"
        fill="#9EA097"
      />
      <path
        d="M370.722 131.387C370.459 131.387 370.201 131.408 369.949 131.45C372.214 131.819 373.942 133.783 373.942 136.152V155.466C373.942 157.835 372.213 159.8 369.949 160.169C370.201 160.21 370.458 160.232 370.722 160.232C373.354 160.232 375.488 158.098 375.488 155.466V136.152C375.488 133.52 373.354 131.387 370.722 131.387Z"
        fill="black"
      />
      <path
        d="M319.517 206.556H122.555V161.883H311.29L322.673 178.716V200.721L319.517 206.556Z"
        fill="black"
      />
      <path
        d="M169.334 225.78C178.79 225.78 186.457 213.274 186.457 197.847C186.457 182.42 178.79 169.914 169.334 169.914C159.877 169.914 152.211 182.42 152.211 197.847C152.211 213.274 159.877 225.78 169.334 225.78Z"
        fill="#031C30"
      />
      <path
        d="M183.761 217.562H169.316V225.789H183.761V217.562Z"
        fill="#031C30"
      />
      <path
        d="M183.794 169.918H169.35V178.144H183.794V169.918Z"
        fill="#031C30"
      />
      <path
        d="M183.775 225.791C193.232 225.791 200.898 213.286 200.898 197.859C200.898 182.432 193.232 169.926 183.775 169.926C174.319 169.926 166.652 182.432 166.652 197.859C166.652 213.286 174.319 225.791 183.775 225.791Z"
        fill="#051423"
      />
      <path
        d="M183.776 214.689C189.492 214.689 194.126 207.13 194.126 197.805C194.126 188.481 189.492 180.922 183.776 180.922C178.06 180.922 173.426 188.481 173.426 197.805C173.426 207.13 178.06 214.689 183.776 214.689Z"
        fill="#FFD00B"
      />
      <path
        d="M182.258 181.113C177.266 182.317 173.433 189.33 173.426 197.806C173.419 206.283 177.243 213.302 182.233 214.513C187.225 213.31 191.058 206.297 191.065 197.82C191.072 189.343 187.248 182.323 182.258 181.113Z"
        fill="#D8D8D8"
      />
      <path
        d="M182.767 191.906C181.003 192.332 179.647 194.812 179.645 197.809C179.642 200.806 180.994 203.288 182.758 203.716C184.522 203.29 185.878 200.81 185.881 197.813C185.883 194.817 184.531 192.334 182.767 191.906Z"
        fill="#051423"
      />
      <path
        d="M188.532 156.532L188.27 131.852H130.301V158.923H142.162V197.856C147.323 197.751 157.624 198.385 157.624 197.904C157.624 179.545 166.747 164.663 178.001 164.663H196.497V156.532H188.532Z"
        fill="#FFCE00"
      />
      <path
        d="M258.489 124.582H167.039V131.852H258.489V124.582Z"
        fill="#031C30"
      />
      <path
        d="M256.393 131.877H297.902C292.578 114.147 279.153 87.3105 250.01 85.75H219.078C219.079 111.226 235.785 131.877 256.393 131.877Z"
        fill="#051423"
      />
      <path
        d="M239.773 85.7422H154.005C126.305 85.7422 100.353 99.5932 85.2149 122.791C83.3106 125.71 81.5975 128.74 80.0977 131.869H173.831C197.995 131.869 208.483 121.773 212.924 106.741C219.104 121.659 231.665 131.869 246.156 131.869H287.665H290.056C284.795 111.745 265.663 85.743 239.773 85.7422Z"
        fill="#031C30"
      />
      <path
        d="M275.803 128.889C275.803 128.889 262.793 94.5469 245.096 94.5469H131.546C124.916 94.5469 118.434 96.5692 113.014 100.388C102.205 108.002 93.4103 117.613 87.6914 128.525L275.803 128.889Z"
        fill="#8ABF4D"
      />
      <mask
        id="mask0_80_24394"
        maskUnits="userSpaceOnUse"
        x="87"
        y="94"
        width="189"
        height="35"
      >
        <path
          d="M275.805 128.889C275.805 128.889 262.795 94.5469 245.098 94.5469H131.548C124.918 94.5469 118.436 96.5692 113.016 100.388C102.207 108.002 93.4122 117.613 87.6934 128.525L275.805 128.889Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_80_24394)">
        <path
          d="M289.099 124H221.947L218.142 108.32C216.864 103.054 220.854 97.9807 226.274 97.9807H272.053L289.099 124Z"
          fill="#B5FF4D"
        />
        <path
          d="M230.718 172.214H211.571C206.63 172.214 203 167.579 204.184 162.783L215.447 117.16C216.285 113.762 219.334 111.375 222.833 111.375H241.98C246.92 111.375 250.55 116.01 249.366 120.807L238.104 166.429C237.266 169.827 234.217 172.214 230.718 172.214Z"
          fill="#73A632"
        />
        <path
          d="M186.337 172.214H167.19C162.25 172.214 158.62 167.579 159.803 162.783L171.066 117.16C171.904 113.762 174.953 111.375 178.452 111.375H197.599C202.539 111.375 206.169 116.01 204.986 120.807L193.723 166.429C192.885 169.827 189.836 172.214 186.337 172.214Z"
          fill="#73A632"
        />
        <path
          d="M101.799 128.883L102.757 115.123C114.331 101.141 122.627 97.8592 142.601 92.3611C141.6 90.3329 131.657 87.0931 129.57 87.9669C107.957 97.0158 94.8149 107.536 85.1543 128.883C85.1543 128.883 102.418 129.525 101.799 128.883Z"
          fill="#73A632"
        />
        <g opacity="0.25">
          <path
            d="M272.887 79.6998L259.459 71.508L206.506 158.309L219.934 166.501L272.887 79.6998Z"
            fill="white"
          />
        </g>
        <path
          d="M189.125 133.573C188.039 133.573 186.942 133.541 185.824 133.478L186.112 128.402C199.417 129.152 209.755 125.147 221.785 114.567L225.142 118.384C213.168 128.916 202.222 133.573 189.125 133.573Z"
          fill="white"
        />
        <path
          d="M194.397 130.41H105.338C105.338 127.243 107.905 124.676 111.072 124.676H194.396V130.41H194.397Z"
          fill="#73A632"
        />
        <g opacity="0.25">
          <path
            d="M148.424 79.6959L134.996 71.5041L82.0431 158.305L95.4711 166.497L148.424 79.6959Z"
            fill="white"
          />
        </g>
        <g opacity="0.25">
          <path
            d="M180.641 80.8209L167.213 72.6291L114.26 159.43L127.688 167.622L180.641 80.8209Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M284.412 225.78C293.869 225.78 301.535 213.274 301.535 197.847C301.535 182.42 293.869 169.914 284.412 169.914C274.955 169.914 267.289 182.42 267.289 197.847C267.289 213.274 274.955 225.78 284.412 225.78Z"
        fill="#031C30"
      />
      <path
        d="M298.837 217.562H284.393V225.789H298.837V217.562Z"
        fill="#031C30"
      />
      <path
        d="M298.872 169.918H284.428V178.144H298.872V169.918Z"
        fill="#031C30"
      />
      <path
        d="M298.853 225.791C308.31 225.791 315.976 213.286 315.976 197.859C315.976 182.432 308.31 169.926 298.853 169.926C289.397 169.926 281.73 182.432 281.73 197.859C281.73 213.286 289.397 225.791 298.853 225.791Z"
        fill="#051423"
      />
      <path
        d="M298.854 214.689C304.57 214.689 309.204 207.13 309.204 197.805C309.204 188.481 304.57 180.922 298.854 180.922C293.138 180.922 288.504 188.481 288.504 197.805C288.504 207.13 293.138 214.689 298.854 214.689Z"
        fill="#FFD00B"
      />
      <path
        d="M297.336 181.113C292.345 182.317 288.511 189.33 288.504 197.806C288.497 206.283 292.321 213.302 297.311 214.513C302.303 213.31 306.137 206.297 306.143 197.82C306.149 189.343 302.325 182.323 297.336 181.113Z"
        fill="#D8D8D8"
      />
      <path
        d="M297.845 191.906C296.081 192.332 294.725 194.812 294.723 197.809C294.72 200.806 296.073 203.288 297.836 203.716C299.601 203.29 300.956 200.81 300.959 197.813C300.961 194.817 299.609 192.334 297.845 191.906Z"
        fill="#051423"
      />
      <path
        d="M270.44 206.561C273.522 206.561 275.886 203.861 275.522 200.8C275.329 199.172 275.227 197.502 275.227 195.799C275.227 178.603 283.263 164.661 298.09 164.661C312.343 164.661 320.953 178.602 320.953 195.799C320.953 199.582 320.455 203.206 319.548 206.561C319.548 206.561 326.788 206.656 327.937 196.899C327.937 196.899 332.719 174.036 314.353 161.788C314.322 145.25 306.126 131.852 297.9 131.852H188.273L188.861 159.701L203.023 171L231.106 206.561H270.44Z"
        fill="#FFCE00"
      />
      <path
        d="M164.668 132.418H172.956L172.968 131.844H77.1761C61.0399 131.844 45.9082 145.268 45.9082 161.829V165.899H128.337C129.157 147.284 146.356 132.418 164.668 132.418Z"
        fill="#FFDA40"
      />
      <path
        d="M222.615 225.78C232.072 225.78 239.738 213.274 239.738 197.847C239.738 182.42 232.072 169.914 222.615 169.914C213.158 169.914 205.492 182.42 205.492 197.847C205.492 213.274 213.158 225.78 222.615 225.78Z"
        fill="#031C30"
      />
      <path
        d="M237.042 217.562H222.598V225.789H237.042V217.562Z"
        fill="#031C30"
      />
      <path
        d="M237.075 169.918H222.631V178.144H237.075V169.918Z"
        fill="#031C30"
      />
      <path
        d="M237.056 225.791C246.513 225.791 254.179 213.286 254.179 197.859C254.179 182.432 246.513 169.926 237.056 169.926C227.6 169.926 219.934 182.432 219.934 197.859C219.934 213.286 227.6 225.791 237.056 225.791Z"
        fill="#051423"
      />
      <path
        d="M237.057 214.689C242.773 214.689 247.407 207.13 247.407 197.805C247.407 188.481 242.773 180.922 237.057 180.922C231.341 180.922 226.707 188.481 226.707 197.805C226.707 207.13 231.341 214.689 237.057 214.689Z"
        fill="black"
      />
      <path
        d="M235.539 181.113C230.549 182.317 226.714 189.33 226.707 197.806C226.7 206.283 230.524 213.302 235.513 214.513C240.505 213.31 244.339 206.297 244.346 197.82C244.353 189.343 240.529 182.323 235.539 181.113Z"
        fill="#9EA097"
      />
      <path
        d="M236.049 191.906C234.284 192.332 232.928 194.812 232.926 197.809C232.923 200.806 234.276 203.288 236.039 203.716C237.804 203.29 239.159 200.81 239.162 197.813C239.165 194.817 237.812 192.334 236.049 191.906Z"
        fill="#051423"
      />
      <path
        d="M269.947 129.253C269.947 129.253 229.794 169.913 218.984 194.799H206.643V206.565H239.741C239.741 206.565 243.514 206.481 245.401 206.525C251.894 206.676 258.953 206.626 264.085 202.106C267.681 198.938 269.726 194.059 271.538 189.317C278.502 171.087 283.91 152.091 287.666 132.682C288.071 130.594 286.94 129.113 286.94 129.113L269.947 129.253Z"
        fill="#031C30"
      />
      <path
        d="M63.2433 225.791C72.458 225.791 79.928 213.285 79.928 197.859C79.928 182.432 72.458 169.926 63.2433 169.926C54.0286 169.926 46.5586 182.432 46.5586 197.859C46.5586 213.285 54.0286 225.791 63.2433 225.791Z"
        fill="#031C30"
      />
      <path
        d="M77.3165 217.57H63.2422V225.797H77.3165V217.57Z"
        fill="#031C30"
      />
      <path
        d="M77.3165 169.926H63.2422V178.152H77.3165V169.926Z"
        fill="#031C30"
      />
      <path
        d="M77.3175 225.791C86.5322 225.791 94.0022 213.285 94.0022 197.859C94.0022 182.432 86.5322 169.926 77.3175 169.926C68.1028 169.926 60.6328 182.432 60.6328 197.859C60.6328 213.285 68.1028 225.791 77.3175 225.791Z"
        fill="#051423"
      />
      <path
        d="M77.3177 214.689C82.8877 214.689 87.403 207.13 87.403 197.805C87.403 188.481 82.8877 180.922 77.3177 180.922C71.7478 180.922 67.2324 188.481 67.2324 197.805C67.2324 207.13 71.7478 214.689 77.3177 214.689Z"
        fill="black"
      />
      <path
        d="M75.8263 181.113C70.9635 182.32 67.2324 189.336 67.2324 197.813C67.2324 206.29 70.9635 213.306 75.8263 214.513C80.689 213.306 84.4202 206.29 84.4202 197.813C84.4193 189.336 80.6882 182.32 75.8263 181.113Z"
        fill="#9EA097"
      />
      <path
        d="M76.3314 191.906C74.6125 192.333 73.293 194.814 73.293 197.81C73.293 200.807 74.6125 203.288 76.3314 203.715C78.0504 203.288 79.3699 200.807 79.3699 197.81C79.3699 194.814 78.0512 192.333 76.3314 191.906Z"
        fill="#051423"
      />
      <g opacity="0.5">
        <path
          d="M131.287 160.809H63.6172C62.8032 160.809 62.1426 160.148 62.1426 159.334C62.1426 158.519 62.8032 157.859 63.6172 157.859H131.287C132.101 157.859 132.762 158.519 132.762 159.334C132.762 160.148 132.102 160.809 131.287 160.809Z"
          fill="white"
        />
      </g>
      <path
        d="M143.026 160.827H127.244C127.244 150.313 135.768 141.789 146.282 141.789H162.064C162.064 152.303 153.541 160.827 143.026 160.827Z"
        fill="white"
      />
      <path
        d="M143.756 156.519H135.043C135.043 150.714 139.749 146.008 145.554 146.008H154.266C154.266 151.813 149.56 156.519 143.756 156.519Z"
        fill="#D8D8D8"
      />
      <path
        d="M142.66 171.671V160.832H45.908V172.354C40.9972 175.632 37.7051 181.652 37.7051 188.538V190.522C37.7051 200.91 45.196 209.331 54.4361 209.331H135.249C144.489 209.331 151.98 209.389 151.98 199V188.538C151.981 181.142 148.183 174.744 142.66 171.671Z"
        fill="#FFDA40"
      />
      <path
        d="M116.973 178.338H74.0254C67.5592 178.338 62.3164 173.096 62.3164 166.629V166.477H128.682V166.629C128.682 173.095 123.439 178.338 116.973 178.338Z"
        fill="#031C30"
      />
      <path
        d="M136.427 197.859C135.918 194.554 133.062 192.023 129.615 192.023H60.1642C56.7171 192.023 53.8615 194.554 53.3517 197.859H37.7051C37.7422 197.859 37.5635 209.529 45.4614 209.529H146.464C154.362 209.529 157.946 205.32 157.946 197.859H136.427Z"
        fill="#031C30"
      />
      <path
        d="M45.6875 160.626C46.552 160.752 47.4351 160.819 48.3333 160.819H63.7111C63.7111 153.162 59.3051 146.561 52.9526 143.539C48.8254 148.261 46.1122 154.169 45.6875 160.626Z"
        fill="white"
      />
      <path
        d="M47.5303 156.522H56.0196C56.0196 152.346 53.6468 148.738 50.2089 147.043C48.3459 149.899 46.9632 153.064 46.2031 156.434C46.6379 156.491 47.0811 156.522 47.5303 156.522Z"
        fill="#D8D8D8"
      />
      <path
        d="M309.823 146.396H305.867C304.106 146.396 302.68 144.969 302.68 143.208V141.262C302.68 139.501 304.107 138.074 305.867 138.074H309.823C311.584 138.074 313.011 139.502 313.011 141.262V143.208C313.01 144.969 311.583 146.396 309.823 146.396Z"
        fill="#CD4D4C"
      />
      <path
        d="M313.269 157.956H309.313C307.552 157.956 306.125 156.529 306.125 154.768V152.821C306.125 151.06 307.552 149.633 309.313 149.633H313.269C315.029 149.633 316.456 151.06 316.456 152.821V154.768C316.456 156.529 315.029 157.956 313.269 157.956Z"
        fill="#CD4D4C"
      />
      <path
        d="M172.965 131.865L178.096 164.632L182.244 164.676C195.828 164.676 205.524 177.399 205.524 199.161C205.524 199.642 205.518 200.12 205.505 200.596C215.135 200.793 222.755 200.322 232.385 200.519C238.878 200.652 245.937 200.607 251.069 196.626C254.665 193.836 256.71 189.539 258.522 185.362C266.236 167.578 272.32 150.785 276.085 131.77L172.965 131.865Z"
        fill="#FFDA40"
      />
      <path
        d="M255.809 131.501V133.68C255.809 142.936 262.446 150.657 271.263 152.427C273.319 145.706 275.083 138.814 276.545 131.5H255.809V131.501Z"
        fill="#031C30"
      />
      <path
        d="M245.398 91.2727L241.359 92.457L252.552 130.625L256.591 129.441L245.398 91.2727Z"
        fill="#031C30"
      />
      <path
        d="M174.708 131.274L170.656 130.131C170.782 129.688 173.792 119.152 179.598 109.792C186.502 98.6616 194.746 91.1876 195.094 90.875L197.91 94.0028C197.829 94.0753 189.749 101.411 183.176 112.01C177.679 120.872 174.736 131.172 174.708 131.274Z"
        fill="#031C30"
      />
      <path
        d="M180.236 124.539V130.884L196.625 130.044C199.556 129.894 201.855 127.474 201.855 124.539H180.236Z"
        fill="#031C30"
      />
      <path
        d="M201.854 124.477C201.82 121.572 199.534 119.187 196.625 119.037L184.178 118.4C182.034 118.29 180.236 119.998 180.236 122.144V124.476L201.854 124.477Z"
        fill="#FFCE00"
      />
      <g opacity="0.5">
        <path
          d="M266.477 143.423C264.179 143.423 262.316 141.56 262.316 139.263V138.832H270.639V139.263C270.638 141.56 268.775 143.423 266.477 143.423Z"
          fill="black"
        />
      </g>
      <path
        d="M270.347 140.939H262.694C261.743 140.939 260.973 140.168 260.973 139.218C260.973 138.266 261.744 137.496 262.694 137.496H270.347C271.298 137.496 272.068 138.267 272.068 139.218C272.068 140.168 271.297 140.939 270.347 140.939Z"
        fill="#051423"
      />
      <g opacity="0.5">
        <path
          d="M253.847 158.923H173.971C171.409 158.923 169.332 156.846 169.332 154.284C169.332 151.722 171.409 149.645 173.971 149.645H253.847C256.409 149.645 258.486 151.722 258.486 154.284C258.486 156.846 256.409 158.923 253.847 158.923Z"
          fill="white"
        />
      </g>
      <path
        d="M241.01 128.829L215.846 128.83L218.358 119.122C219.409 115.145 223.419 112.772 227.396 113.823L236.62 116.263C240.67 117.334 243.084 121.485 242.013 125.533L241.01 128.829Z"
        fill="white"
      />
      <path
        d="M230.577 117.786L230.619 117.797C232.314 118.274 234.075 117.285 234.55 115.59L235.759 111.286C236.235 109.59 235.247 107.83 233.551 107.354L233.509 107.342C231.814 106.866 230.054 107.855 229.578 109.549L228.369 113.853C227.894 115.55 228.882 117.31 230.577 117.786Z"
        fill="#FC9973"
      />
      <path
        d="M242.672 89.2945L240.887 89.0156L237.943 107.851C239.623 108.123 240.214 107.843 242.461 102.201L245.155 95.4751C246.956 90.9174 244.763 89.6215 242.672 89.2945Z"
        fill="#051423"
      />
      <path
        d="M234.311 87.8913C238.679 88.5738 241.646 92.6883 240.963 97.0573L240.652 99.0711L241.541 99.2102C242.772 99.4023 243.615 100.557 243.423 101.789L243.257 102.853C243.065 104.084 241.91 104.926 240.678 104.734L239.8 104.597L239.579 106.031C238.896 110.399 234.801 113.387 230.433 112.705L226.925 112.157C223.166 111.169 223.062 107.422 223.442 104.991L225.425 92.8054C226.108 88.4364 229.943 87.2087 234.311 87.8913Z"
        fill="#FFBFA7"
      />
      <path
        d="M224.819 96.668L223.979 102.042C223.06 101.642 222.488 100.663 222.651 99.6273L222.817 98.563C222.979 97.5266 223.822 96.7691 224.819 96.668Z"
        fill="#FC9973"
      />
      <path
        d="M228.008 100.028C227.94 100.463 227.531 100.762 227.095 100.693C226.66 100.625 226.361 100.216 226.43 99.7809C226.498 99.3452 226.907 99.0469 227.342 99.1152C227.778 99.1826 228.076 99.5913 228.008 100.028Z"
        fill="#051423"
      />
      <path
        d="M234.231 101.012C234.162 101.448 233.754 101.746 233.318 101.678C232.882 101.61 232.584 101.201 232.652 100.765C232.721 100.33 233.129 100.031 233.565 100.1C234 100.168 234.298 100.576 234.231 101.012Z"
        fill="#051423"
      />
      <path
        d="M232.59 96.6598L235.545 97.1216C235.749 97.1536 235.94 97.0137 235.971 96.8107C236.003 96.6067 235.863 96.4155 235.659 96.3843L232.704 95.9225C232.5 95.8905 232.309 96.0304 232.278 96.2335C232.246 96.4374 232.386 96.6286 232.59 96.6598Z"
        fill="#051423"
      />
      <path
        d="M226.408 96.1833L229.363 96.645C229.566 96.677 229.758 96.5372 229.789 96.3341C229.821 96.1302 229.681 95.9389 229.478 95.9077L226.523 95.446C226.319 95.414 226.129 95.5538 226.097 95.7569C226.064 95.9608 226.204 96.1512 226.408 96.1833Z"
        fill="#051423"
      />
      <path
        d="M234.104 104.544C233.491 105.932 231.698 107.176 228.957 106.748C226.216 106.32 225.611 104.701 225.451 103.191L234.104 104.544Z"
        fill="#EFF4DF"
      />
      <path
        d="M239.367 103.141C236.644 102.871 239.792 94.9498 239.792 94.9498L242.454 94.0625C242.454 94.0625 241.489 102.897 239.367 103.141Z"
        fill="#051423"
      />
      <path
        d="M230.305 102.44C229.602 102.44 229.084 102.216 228.879 101.809C228.691 101.435 228.688 100.755 229.744 99.6455C229.827 99.5587 229.964 99.5553 230.051 99.6379C230.138 99.7205 230.141 99.8578 230.059 99.9446C229.379 100.657 229.092 101.267 229.266 101.615C229.426 101.93 229.98 102.071 230.709 101.981C230.835 101.962 230.936 102.051 230.951 102.17C230.965 102.289 230.88 102.397 230.761 102.412C230.601 102.431 230.449 102.44 230.305 102.44Z"
        fill="#FFBFA7"
      />
      <path
        d="M240.891 87.142C239.205 86.2766 237.392 85.6565 235.528 85.3118C232.855 84.8172 230.676 84.1069 228.366 85.6278C225.964 87.2094 227.43 90.3667 229.165 91.2489C231.79 92.5845 234.509 93.7178 237.227 94.8452C239.408 95.7502 242.078 96.5507 244.241 95.157C247.089 93.3218 245.648 90.3254 243.472 88.7329C242.654 88.1355 241.791 87.6038 240.891 87.142Z"
        fill="#051423"
      />
      <path
        d="M263.134 84.9852C263.12 84.9852 263.107 84.9852 263.093 84.9843C261.959 84.9624 261.058 84.0246 261.08 82.8913C261.104 81.6686 261.121 77.6594 261.094 76.532C261.067 75.3987 261.964 74.4575 263.097 74.4305C263.115 74.4297 263.131 74.4297 263.147 74.4297C264.258 74.4297 265.172 75.317 265.199 76.4334C265.23 77.6999 265.209 81.8144 265.186 82.9713C265.164 84.092 264.249 84.9852 263.134 84.9852Z"
        fill="#FFBFA7"
      />
      <path
        d="M241.614 123.625L239.975 118.813C252.434 114.571 258.793 103.012 260.595 81.332L265.661 81.7533C263.668 105.728 256.251 118.641 241.614 123.625Z"
        fill="white"
      />
      <path
        d="M409.534 227.405H1.14474C0.684669 227.405 0.310547 227.033 0.310547 226.572C0.310547 226.111 0.683827 225.738 1.14474 225.738H409.534C409.995 225.738 410.368 226.111 410.368 226.572C410.368 227.033 409.995 227.405 409.534 227.405Z"
        fill="white"
      />
      <path
        d="M115.478 239.772H96.0998C95.6397 239.772 95.2656 239.4 95.2656 238.939C95.2656 238.478 95.6389 238.105 96.0998 238.105H115.478C115.938 238.105 116.313 238.478 116.313 238.939C116.312 239.4 115.938 239.772 115.478 239.772Z"
        fill="white"
      />
      <path
        d="M70.6743 239.772H29.3127C28.8526 239.772 28.4785 239.4 28.4785 238.939C28.4785 238.478 28.8518 238.105 29.3127 238.105H70.6743C71.1344 238.105 71.5085 238.478 71.5085 238.939C71.5085 239.4 71.1352 239.772 70.6743 239.772Z"
        fill="white"
      />
      <path
        d="M334.43 223.57H328.418C307.439 223.57 290.371 208.485 290.371 189.942V142.888C290.371 142.102 291.092 141.465 291.981 141.465C292.871 141.465 293.591 142.102 293.591 142.888V189.942C293.591 206.915 309.215 220.724 328.418 220.724H334.43C353.633 220.724 369.256 206.915 369.256 189.942V142.888C369.256 142.102 369.977 141.465 370.866 141.465C371.756 141.465 372.476 142.102 372.476 142.888V189.942C372.476 208.485 355.408 223.57 334.43 223.57Z"
        fill="#34B44A"
      />
      <path
        d="M334.43 223.57H328.418C307.439 223.57 290.371 208.485 290.371 189.942V142.888C290.371 142.102 291.092 141.465 291.981 141.465C292.871 141.465 293.591 142.102 293.591 142.888V189.942C293.591 206.915 309.215 220.724 328.418 220.724H334.43C353.633 220.724 369.256 206.915 369.256 189.942V142.888C369.256 142.102 369.977 141.465 370.866 141.465C371.756 141.465 372.476 142.102 372.476 142.888V189.942C372.476 208.485 355.408 223.57 334.43 223.57Z"
        fill="#051423"
      />
      <path
        d="M291.548 153.018C289.104 153.018 287.123 151.036 287.123 148.593V142.523C287.123 140.079 289.104 138.098 291.548 138.098C293.993 138.098 295.974 140.079 295.974 142.523V148.593C295.974 151.036 293.993 153.018 291.548 153.018Z"
        fill="#031C30"
      />
      <path
        d="M291.548 158.905C290.153 158.905 289.023 157.775 289.023 156.38V142.915C289.023 141.521 290.153 140.391 291.548 140.391C292.942 140.391 294.072 141.521 294.072 142.915V156.38C294.072 157.775 292.942 158.905 291.548 158.905Z"
        fill="#F9F3EF"
      />
      <path
        d="M281.863 10.1094L262.654 43.5983L282.943 45.8589L273.993 72.0806L298.438 39.403L275.437 37.1424L281.863 10.1094Z"
        fill="#FFCE00"
      />
      <path
        d="M296.784 64.5625L286.648 82.2325L297.354 83.4256L292.631 97.2615L305.529 80.0195L293.393 78.8264L296.784 64.5625Z"
        fill="#FFCE00"
      />
    </svg>
  );
};

import React from "react";

export const ClockIconSvg = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 18.8333C14.6017 18.8333 18.3327 15.1024 18.3327 10.5C18.3327 5.89762 14.6017 2.16666 9.99935 2.16666C5.39698 2.16666 1.66602 5.89762 1.66602 10.5C1.66602 15.1024 5.39698 18.8333 9.99935 18.8333Z"
        fill="#71CB80"
      />
      <path d="M10 5.5V10.5L13.3333 12.1667" fill="#71CB80" />
      <path
        d="M10 5.5V10.5L13.3333 12.1667"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

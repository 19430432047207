import React from 'react';

const ChargerProfileSvg
 = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_411_15184)">
      <rect width="48" height="48" rx="8" fill="#289997"/>
      <path d="M21.2988 29.1571L28.1701 22.8954L35.6509 41.6805L21.2988 29.1571Z" fill="black"/>
      <path d="M35.0977 16.8559L28.0407 23.2838L20.3577 3.99998L35.0977 16.8559Z" fill="white"/>
      <path d="M21.7428 42.0116L11.2142 42.0115L20.5237 4.2749L21.7428 42.0116Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_411_15184">
        <rect width="48" height="48" rx="8" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default ChargerProfileSvg
;
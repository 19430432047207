// dashboardsvg.jsx
import React from 'react';

export const SessionsIcon = ({ className }) => (
	<svg
		className={className}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 6.67969V10.8464"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.0007 18.3411C5.97565 18.3411 2.70898 15.0745 2.70898 11.0495C2.70898 7.02448 5.97565 3.75781 10.0007 3.75781C14.0257 3.75781 17.2923 7.02448 17.2923 11.0495"
			stroke="white"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.5 1.67969H12.5"
			stroke="white"
			strokeWidth="1.25"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.417 15.4233V14.4566C12.417 13.265 13.267 12.7733 14.3003 13.3733L15.1337 13.8566L15.967 14.34C17.0003 14.94 17.0003 15.915 15.967 16.515L15.1337 16.9983L14.3003 17.4816C13.267 18.0816 12.417 17.59 12.417 16.3983V15.4233Z"
			stroke="white"
			strokeWidth="1.25"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

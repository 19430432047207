import React from "react";

export const StoreIconSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1949 7.40967L16.9935 5.48606C16.7018 3.38883 15.7504 2.53467 13.7157 2.53467H12.0768H11.049H8.93791H7.91013H6.24346C4.2018 2.53467 3.25735 3.38883 2.95874 5.50689L2.77124 7.41661C2.7018 8.15967 2.90319 8.88189 3.34069 9.44439C3.86846 10.1319 4.68096 10.5208 5.58374 10.5208C6.45874 10.5208 7.29902 10.0833 7.8268 9.38189C8.29902 10.0833 9.10458 10.5208 10.0004 10.5208C10.8962 10.5208 11.681 10.1041 12.1601 9.40967C12.6949 10.0972 13.5212 10.5208 14.3824 10.5208C15.306 10.5208 16.1393 10.1111 16.6601 9.38883C17.0768 8.83328 17.2643 8.13189 17.1949 7.40967Z"
        fill="#71CB80"
      />
      <path
        d="M9.55056 13.2361C8.66862 13.3264 8.00195 14.0764 8.00195 14.9652V16.868C8.00195 17.0555 8.15473 17.2083 8.34223 17.2083H11.6547C11.8422 17.2083 11.995 17.0555 11.995 16.868V15.2083C12.002 13.7569 11.1478 13.0694 9.55056 13.2361Z"
        fill="#71CB80"
      />
      <path
        d="M16.508 11.6667V13.7361C16.508 15.6528 14.9525 17.2083 13.0358 17.2083C12.8483 17.2083 12.6955 17.0555 12.6955 16.868V15.2083C12.6955 14.3194 12.4247 13.625 11.8969 13.1528C11.4316 12.7292 10.7997 12.5208 10.015 12.5208C9.84136 12.5208 9.66775 12.5278 9.48025 12.5486C8.24414 12.6736 7.30664 13.7153 7.30664 14.9653V16.868C7.30664 17.0555 7.15386 17.2083 6.96636 17.2083C5.0497 17.2083 3.49414 15.6528 3.49414 13.7361V11.6805C3.49414 11.1944 3.97331 10.868 4.4247 11.0278C4.6122 11.0903 4.7997 11.1389 4.99414 11.1667C5.07747 11.1805 5.16775 11.1944 5.25108 11.1944C5.3622 11.2083 5.47331 11.2153 5.58442 11.2153C6.38997 11.2153 7.18164 10.9167 7.80664 10.4028C8.40386 10.9167 9.18164 11.2153 10.0011 11.2153C10.8275 11.2153 11.5914 10.9305 12.1886 10.4167C12.8136 10.9236 13.5914 11.2153 14.383 11.2153C14.508 11.2153 14.633 11.2083 14.7511 11.1944C14.8344 11.1875 14.9108 11.1805 14.9872 11.1667C15.2025 11.1389 15.3969 11.0764 15.5914 11.0139C16.0427 10.8611 16.508 11.1944 16.508 11.6667Z"
        fill="#71CB80"
      />
    </svg>
  );
};

import React, { useState } from "react";

const ChargersBookings = ({ bookings }) => {
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(bookings.length / itemsPerPage);
  const currentBookings = bookings.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / 2) * 2;
    return new Array(2).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h2 className="text-lg font-semibold">Bookings</h2>
      <div className="overflow-x-auto mt-3">
        <table className="w-full text-sm text-left text-black-100">
          <thead className="text-xs text-gray-700 uppercase ">
            <tr>
              <th scope="col" className="py-3 px-6">Charger & Time</th>
              <th scope="col" className="py-3 px-6 text-right">Booking Status</th>
            </tr>
          </thead>
          <tbody>
            {currentBookings.map((booking, index) => (
              <tr key={index} className="bg-white border-b">
                <td className="py-4 px-6">{booking.type}, <br /> <span className="text-grey-500">{booking.time}</span></td>
                <td className="py-4 px-6 text-right">
                  <span className={`px-3 py-1 uppercase h-[22px] w-[63px] leading-wide font-bold text-xs rounded-full shadow-sm ${booking.status === 'Ongoing' ? 'bg-[#ECFDF3] text-[#027A48]' : booking.status === 'Cancelled' ? 'bg-[#FFF1F3] text-[#AE0000]' : booking.status === 'Pending' ? 'bg-[#FFF5F1] text-[#FF9922]' : 'bg-[#EFF8FF] text-[#1448A1]'}`}>
                    {booking.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          <ul className="flex list-none">
            {currentPage > 1 && (
              <li className="mx-1">
                <button onClick={() => paginate(currentPage - 1)} className="text-gray-500 hover:text-green-600">
                  &lt;
                </button>
              </li>
            )}
            {getPaginationGroup().map((item, index) => (
              <li key={index} className="mx-1">
                <button onClick={() => paginate(item)} className={`px-4 py-1 rounded-full ${item === currentPage ? "bg-green-500 text-white" : "bg-white border border-gray-300 text-gray-500 hover:bg-gray-200"}`}>
                  {item}
                </button>
              </li>
            ))}
            <li className="mx-1">...</li>
            {currentPage < pageCount && (
              <li className="mx-1">
                <button onClick={() => paginate(currentPage + 1)} className="text-gray-500 hover:text-green-600">
                  &gt;
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChargersBookings;




// dashboardsvg.jsx
import React from 'react';

export const DashboardIcon = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3333 9.08854V3.42188C18.3333 2.17187 17.8 1.67188 16.475 1.67188H13.1083C11.7833 1.67188 11.25 2.17187 11.25 3.42188V9.08854C11.25 10.3385 11.7833 10.8385 13.1083 10.8385H16.475C17.8 10.8385 18.3333 10.3385 18.3333 9.08854Z" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.3333 16.5781V15.0781C18.3333 13.8281 17.8 13.3281 16.475 13.3281H13.1083C11.7833 13.3281 11.25 13.8281 11.25 15.0781V16.5781C11.25 17.8281 11.7833 18.3281 13.1083 18.3281H16.475C17.8 18.3281 18.3333 17.8281 18.3333 16.5781Z" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.74935 10.9219V16.5885C8.74935 17.8385 8.21602 18.3385 6.89102 18.3385H3.52435C2.19935 18.3385 1.66602 17.8385 1.66602 16.5885V10.9219C1.66602 9.67188 2.19935 9.17188 3.52435 9.17188H6.89102C8.21602 9.17188 8.74935 9.67188 8.74935 10.9219Z" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.74935 3.42187V4.92188C8.74935 6.17187 8.21602 6.67187 6.89102 6.67187H3.52435C2.19935 6.67187 1.66602 6.17187 1.66602 4.92188V3.42187C1.66602 2.17187 2.19935 1.67188 3.52435 1.67188H6.89102C8.21602 1.67188 8.74935 2.17187 8.74935 3.42187Z" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
